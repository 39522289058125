import { Add, BodyCard, CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import Title from "../../../components/Title/Title";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
const PartyNews = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();

  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=party_news"
  );
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/v2/party-news${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/v2/party-news"
  );

  return (
    <Container>
      <Row justify="center">
        <Col md={5}>
          <Title
            update="/update-title/party_news"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>
      
      {isLoading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        <Row justify="end">
          {data?.data.data.data.map((e) => (
            <Col md={6} lg={4}>
              <CardAll
                delete={() => deleteItem(e)}
                update={`/party-news/${e.id}`}
              >
                <h1 className="text-Brown text-xl font-semibold text-center">
                  {e.title}
                </h1>
                {e.media_type === "VIDEO" ? (
                  e.video.substring(0, 4) === "http" ? (
                    <div className=" h-[230px]">
                      <iframe
                        className="video"
                        title="Youtube player"
                        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                        src={`https://youtube.com/embed/${e.video.substring(
                          32,
                          43
                        )}?autoplay=0`}
                      ></iframe>
                    </div>
                  ) : (
                    <div className="flex items-center h-[230px]">
                      <video
                        className="rounded-2xl object-cover container mx-auto w-full h-full "
                        controls
                      >
                        <source src={`${imgUrl}${e.video}`} />
                      </video>
                    </div>
                  )
                ) : (
                  <Row className="pt-4">
                    <Swiper className="" slidesPerView={1} spaceBetween={20}>
                      {e.images.map((e) => (
                        <SwiperSlide key={e.id} className="">
                          <img
                            src={imgUrl + e.image}
                            alt=""
                            className="w-[220px]  mx-auto h-[220px] rounded-2xl"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Row>
                )}

                <p>{parse(e.text)}</p>
                <div className="">
                  <BodyCard name=" : تاريخ" value={e.date} />
                  <BodyCard name=" : الوقت" value={e.time} />
                </div>
              </CardAll>
            </Col>
          ))}
        </Row>
      )}
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link="/party-news/add" />
    </Container>
  );
};

export default PartyNews;
