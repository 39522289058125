import { BiMenu } from "react-icons/bi";
import Logo from "../../images/logo.png";
import { useContextHook } from "../../Context/ContextOPen";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import usePost from "../../Context/usePost";

const Navbar = () => {
  let x = JSON.parse(window.localStorage.getItem("site_info")) || "";
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  const { postRe } = usePost("logout");

  return (
    <>
      <div
      
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full  h-full top-0 left-0 popup z-40 flex justify-center items-center`}
      >
        <div className="bg-white z-50 rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
          <p className="font-semibold text-3xl text-center py-7">
            هل انت متأكد من تسجيل الخروج؟
          </p>
          <div className="flex items-end m-5">
            <Link to="/login" onClick={postRe}>
              <button className=" border px-10 border-Brown bg-Brown text-white font-semibold  p-3 rounded-xl">
                نعم
              </button>
            </Link>
            <button
              onClick={() => setSure(false)}
              className=" border px-10 border-Brown text-Brown bg-white font-semibold  p-3 rounded-xl ml-5"
            >
              تراجع
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white sticky z-40 top-0  border-b border-b-Brown flex-row-reverse  flex items-center justify-between flex-wrap">
        <div>
          <BiMenu
            size={35}
            className="text-Brown cursor-pointer"
            onClick={changeMenu}
          />
        </div>
        <div className="">
          <img src={Logo} alt="" className="w-[70px]" />
        </div>
        {localStorage.getItem("name") && (
          <div className="flex text-2xl font-semibold text-Brown">
            <div>{localStorage.getItem("name")}</div>
            <pre> </pre> <h1 className=" ">اهلاً بك </h1>
          </div>
        )}
        <div className="flex gap-9">
          <div className={`m-1`}>
            <NavLink
              to="/publicInformation"
              className={`m-1  ${x[0] === false ? "hidden" : ""} link`}
            >
              <button className=" mx-2 border border-Brown text-Brown font-semibold bg-white p-3  rounded-xl hover:bg-Brown hover:text-white transition-all">
                معلومات عامة
              </button>
            </NavLink>
            <Link to="/login">
              <button
                onClick={() => setSure(true)}
                className=" mx-1 border border-Brown text-Brown font-semibold bg-white p-3  rounded-xl hover:bg-Brown hover:text-white transition-all"
              >
                تسجيل خروج
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
