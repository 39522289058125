import ReactPaginate from "react-paginate";
import useFetch from "../../Context/useFetch";
import {
  Add,
  BodyCard,
  CardAll,
  Container,
  Error,
} from "../../components/index";
import { baseUrl, imgUrl } from "../../Context/baseUrl";
import imgUser from "../../images/user.jpeg";
import { useEffect, useRef, useState } from "react";
import ExportPdf from "../../images/Export.png";
import filter from "../../images/filter (3).png";
import axios from "axios";
const ViewAdminAccounts = () => {
  const [search, setSearch] = useState("");
  const [awit, setAwit] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [activeExport, setActiveExport] = useState("");
  const [exports, setExports] = useState("");
  const [arrey, setarrey] = useState();
  const { data, loading, error, handlePageClick, deleteItem, deleteError } =
    useFetch(
      `admin/admins?${sortBy}${search ? `&search=${search}` : ""}`,
      "admin/admins"
    );
  let dataAll = data?.data.data.data;

  let jjj = arrey;
  useEffect(() => {}, [activeExport]);
  const selectedCheckboxesRef = useRef([]);
  function handleCheckboxChange(event) {
    const { value, checked } = event.target;
    if (checked) {
      selectedCheckboxesRef.current.push(value);
      setarrey(selectedCheckboxesRef.current);
    } else {
      selectedCheckboxesRef.current = selectedCheckboxesRef.current.filter(
        (item) => item !== value
      );
      setarrey(selectedCheckboxesRef.current);
    }
  }
  const fetchFile = () => {
    setAwit(true);
    axios({
      url: `${baseUrl}admin/admins/export/${exports}?${
        activeExport === "checked" ? jjj?.join("&") : activeExport
      }`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setAwit(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = `file.${exports === "pdf" ? "pdf" : "xlsx"}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
        setAwit(false);
      });
  };
  return (
    <>
      <div className="flex justify-center gap-2 flex-wrap mt-5 my-3">
        <div
          onClick={() => setExports("pdf")}
          className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown  cursor-pointer border border-Brown rounded-xl"
        >
          <select
            className={`mr-5 outline-none border-none ${
              exports === "pdf" ? "" : "hidden"
            }`}
            onChange={(e) => setActiveExport(e.target.value)}
          >
            <option value=""></option>
            <option value="days=all">تحديد الكل </option>
            <option value="checked">تحديد العنصر</option>
          </select>
          <span className="mr-5 ">(PDF) تصدير كملف</span>
          <img src={ExportPdf} className="w-[20px]" alt="" />
        </div>
        <div
          onClick={() => setExports("excel")}
          className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl"
        >
          <select
            className={`mr-5 outline-none border-none ${
              exports === "excel" ? "" : "hidden"
            }`}
            onChange={(e) => setActiveExport(e.target.value)}
          >
            <option value=""></option>
            <option value="days=all">تحديد الكل </option>
            <option value="checked">تحديد العنصر</option>
          </select>

          <span className="mr-5 ">(Excel) تصدير كملف</span>
          <img src={ExportPdf} className="w-[20px]" alt="" />
        </div>
        <div className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl">
          <input
            type="text"
            className=" outline-none border-none"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="بحث"
          />
          <select
            className="mr-5 outline-none border-none"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="">الكل</option>
            <option value="sort_by=name">الاسم</option>
            <option value="sort_by=username">اسم المستخدم</option>
            <option value="sort_by=email">الإيميل</option>
            <option value="sort_by=job">الوظيفة</option>
            <option value="sort_by=age">العمر</option>
            <option value="sort_by=location">عنوان السكن</option>
            <option value="sort_by=role">الصلاحية</option>
            <option value="sort_by=phone_number">الهاتف</option>
          </select>
          <span>فرز حسب </span>
          <img src={filter} className="w-[20px]" alt="" />
        </div>
      </div>
      <Error error={error} onClick={deleteError} />
      <Container>
        <div className={`text-center ${activeExport ? "" : "hidden"} my-2 `}>
          <button
            onClick={fetchFile}
            className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            تحميل الملف
          </button>
        </div>
        {awit ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}
        <div className="flex justify-center flex-wrap gap-3">
          {loading ? (
            <div>
              <div className="loading"></div>
            </div>
          ) : (
            dataAll?.map((e) => (
              <>
                <CardAll
                  check="admins"
                  update={`/admin-account/${e.id}`}
                  delete={() => deleteItem(e)}
                  key={e.id}
                >
                  <input
                    type="checkbox"
                    value={"admins[]=" + e.id}
                    onChange={handleCheckboxChange}
                    className={`${
                      activeExport === "checked" ? "" : "hidden"
                    } absolute -top-3 -left-1 w-5 h-5
          `}
                  />
                  <div className="">
                    <img
                      src={e.image ? imgUrl + e.image : imgUser}
                      alt=""
                      className="w-[70px] h-[70px] mx-auto -mt-12 rounded-full"
                    />
                  </div>
                  <div className="text-end space-y-2 px-2 pt-5 ">
                    <BodyCard value={e.name} name=":الاسم" />
                    <BodyCard value={e.username} name=":اسم المستخدم" />
                    <BodyCard value={e.role} name=":الصلاحية" />
                    <BodyCard value={e.job} name=":الوظيفة" />
                    <BodyCard value={e.age} name=":العمر" />
                    <BodyCard value={e.phone_number} name=":الهاتف" />
                    <BodyCard
                      value={e.email.substring(0, 25)}
                      name=":الإيميل"
                    />
                    <BodyCard value={e.location} name=":عنوان السكن" />
                  </div>
                </CardAll>
              </>
            ))
          )}
        </div>
        <div className="flex justify-center my-3">
          <ReactPaginate
            className="pagination flex"
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            pageCount={Math.ceil(
              data?.data.data.total / data?.data.data.per_page
            )}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            pageClassName="page-item"
            pageLinkClassName="page-link"
          />
        </div>
        <Add check="admins" link="/admin-account/create-account" />
      </Container>
    </>
  );
};

export default ViewAdminAccounts;
