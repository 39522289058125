import { useEffect, useState } from "react";
import { Border, ButtonApp, Container, Imports } from "../../components/index";
import usePost from "../../Context/usePost";
import { useParams } from "react-router-dom";
import useFetch from "../../Context/useFetch";
const PowersTwo = () => {
  const { id } = useParams();
  const [postData, setPostData] = useState("");
  const [description, setDescription] = useState("");
  const { postRe, error, loading } = usePost(
    `admin/roles/${id}`,
    {
      ar_name: postData,
      description: description,
    },
    true
  );

  const { data } = useFetch(`admin/roles/${id}`);

  useEffect(() => {
    setPostData(data?.data.data.ar_name);
    setDescription(data?.data.data.description);
  }, [data]);
  return (
    <>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Container>
        <Border>
          <div className="space-y-5">
            <div className="flex gap-4 max-md:flex-wrap flex-row-reverse  mx-auto ">
              <Imports
                value={postData}
                title=":تعديل اسم الصلاحية"
                onChange={(e) => setPostData(e.target.value)}
              />
              <Imports
                value={description}
                title=":تعديل وصف الصلاحية"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="text-red-500">
              {error && error.map((e) => <div>{e}</div>)}
            </div>
            <ButtonApp onClick={postRe}>
              <span className="px-10">تعديل</span>
            </ButtonApp>
          </div>
        </Border>
      </Container>
    </>
  );
};

export default PowersTwo;
