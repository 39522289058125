import { useEffect, useState } from "react";
import { Border, ButtonApp, Container, Imports } from "../../../components";
import { useParams } from "react-router-dom";
import useFetch from "../../../Context/useFetch";
import { usePOST } from "../../../APIs/useMyAPI";

const HonestyAdd = () => {
  const { id } = useParams();
  const { data } = useFetch(`admin/v2/honesties/${id}`);
  let dataAll = data?.data?.data?.name;
  const { handleChange, setFormData, handleSubmit, formData, error, loading } =
    usePOST("");
  useEffect(() => {
    setFormData({
      name: dataAll,
    });
  }, [dataAll]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? "admin/v2/honesties" : `admin/v2/honesties/${id}`
    );
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <div className="space-y-5">
          <div className="w-1/2 max-sm:w-full mx-auto">
            <Imports
              name="name"
              onChange={handleChange}
              value={formData.name}
              title=":أسم الأمانة"
            />
          </div>
          <div className="text-red-500">{error}</div>
          <ButtonApp onClick={submitDisplay}>
            {id === "add" ? (
              <span className="px-10">أضافة</span>
            ) : (
              <span className="px-10">تعديل</span>
            )}
          </ButtonApp>
        </div>
      </Border>
    </Container>
  );
};

export default HonestyAdd;
