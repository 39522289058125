import { useParams } from "react-router-dom";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { Col, Row } from "../../../Grid-system";
import { Add, BodyCard, CardAll, Container } from "../../../components";
import ReactPaginate from "react-paginate";
import { useEffect, useRef } from "react";

const ProvincialSecretariat4 = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { id } = useParams();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/v2/provincial-hqs/last-sub/${id}/subs${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/v2/provincial-hqs/last-sub/subs"
  );

  return (
    <Container>
      <Row justify="center">
        <Col md={5}>
          <div className="border border-Brown rounded-xl p-4 text-center">
            {data?.data.data.section_name}
          </div>
        </Col>
      </Row>
      {isLoading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Row justify="end">
        {data?.data.data.data.data.map((e) => (
          <Col key={e.id} md={6} lg={4}>
            <CardAll
              delete={() => deleteItem(e)}
              update={`/provincial-secretariat/${id}/page4/${e.id}`}
            >
              <BodyCard name=" : الأسم" value={e.name} />
              <BodyCard name=" : الموقع التنظيمي " value={e.job_title} />
              <BodyCard name=" : رقم الهاتف" value={e.phone_number} />
              <BodyCard name=" : الايميل" value={e.email} />
            </CardAll>
          </Col>
        ))}
      </Row>
      {data?.data.data.data.data == 0 ? (
        <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
      ) : (
        ""
      )}
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            data?.data.data.data.total / data?.data.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link={`/provincial-secretariat/${id}/page4/add `} />
    </Container>
  );
};

export default ProvincialSecretariat4;
