import { Add, Container, DeleteIcon } from "../../../components";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import useFetch from "../../../Context/useFetch";

const Honesty = () => {
  const { data, loading, deleteItem } = useFetch(`admin/v2/honesties`);
  let dataAll = data?.data.data;
  return (
    <Container>
      <div className=" mx-auto w-full  py-4 overflow-x-scroll">
        <table className=" text-2xl text-center w-full mx-auto ">
          <thead className="text-Brown">
            <tr>
              <td>الأمانات</td>
              <td>احداث</td>
            </tr>
          </thead>
          <tbody className="">
            {loading ? (
              <div>
                <div className="loading"></div>
              </div>
            ) : (
              dataAll?.map((e) => (
                <tr>
                  <td>{e.name}</td>
                  <td>
                    <div className="flex justify-center gap-6 ">
                      <Link to={`/honesty/${e.id}`}>
                        <button className="cursor">
                          <BiEdit size={35} color={"Brown"} />
                        </button>
                      </Link>
                      <DeleteIcon onClick={() => deleteItem(e)} />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {dataAll == 0 ? (
          <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
        ) : (
          ""
        )}
      </div>
      <Add link="/honesty/add" />
    </Container>
  );
};

export default Honesty;
