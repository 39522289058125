import Logo from "../../images/logo.png";
import { useState } from "react";
import usePost from "../../Context/usePost";


const EditPassword = () => {
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [password, setPassword] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [biShow, setBiShow] = useState(false);
  const { postRe, error, loading } = usePost("admin/change-password", {
    password_confirmation: password_confirmation,
    password: password,
    old_password: old_password,
  });

  return (
    <div className=" py-3 bg-white flex items-center justify-center text-center ">
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Brown p-10 rounded-2xl">
        <div className="flex justify-center">
          <img src={Logo} alt="" className="w-[120px] " />
        </div>
        <div className="flex  max-sm:flex-col-reverse w-full mx-3">
          <input
            type={biShow ? "text" : "password"}
            onChange={(e) => setOldPassword(e.target.value)}
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none text-end placeholder:text-Brown"
          />
          <pre className="flex items-center max-sm:border-none max-sm:text-end max-sm:block border-b font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
            :كلمة السر الحالية
          </pre>
        </div>
        <div className="flex  max-sm:flex-col-reverse w-full mx-3">
          <input
            type={biShow ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none text-end placeholder:text-Brown"
          />
          <pre className="flex items-center max-sm:border-none max-sm:text-end max-sm:block border-b font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
            :كلمة السر الجديدة
          </pre>
        </div>
        <div className="flex  max-sm:flex-col-reverse w-full mx-3">
          <input
            type={biShow ? "text" : "password"}
            onChange={(e) => setPassword_confirmation(e.target.value)}
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none text-end placeholder:text-Brown"
          />
          <pre className="flex items-center max-sm:border-none max-sm:text-end max-sm:block border-b font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
            :تأكيد كلمة السر
          </pre>
        </div>
        <div className="text-end text-l text-Brown flex items-center justify-end space-x-2">
          <span>عرض كلمة المرور </span>
          <input
            type="checkbox"
            className="relative w-5 h-5"
            onChange={(e) => setBiShow(e.target.checked)}
          />
        </div>
        <div className="text-red-500">
          {error && error.map((e) => <div>{e}</div>)}
        </div>
        <div className="w-fit mx-auto">
          <button
            onClick={postRe}
            className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            <span className="px-12">ارسال</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
