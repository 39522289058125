import { useEffect, useRef, useState } from "react";
import { Add, BodyCard, CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import ReactPaginate from "react-paginate";

const Notifications = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const [active, setActive] = useState("1");
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/v2/notifications?status=${active}${
      filter.get("page") ? "&page=" + filter.get("page") : ""
    }`,
    "admin/v2/notifications"
  );

  return (
    <Container>
      <div className="flex justify-center">
        <button
          onClick={() => setActive("2")}
          className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
        >
          مرسل
        </button>
        <button
          onClick={() => setActive("1")}
          className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
        >
          غير مرسل
        </button>
      </div>
      {data?.data.data.data == 0 ? (
            <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
          ) : (
            ""
          )}
      <Row>
        {isLoading ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          data?.data.data.data.map((e) => (
            <Col key={e.id} md={4}>
              <CardAll
                xx={e.status === "1" ? true : false}
                update={`/notifications/${e.id}`}
                delete={() => deleteItem(e)}
              >
                <BodyCard name=" : عنوان" value={e.title} />
                <BodyCard name=" : النص" value={e.body} />
                <BodyCard name=" : تاريخ" value={e.push_time} />
                <BodyCard
                  name=" : حالة"
                  value={e.status === "1" ? "غير مرسل" : "تم ارساله"}
                />
              </CardAll>
            </Col>
          ))
        )}
      </Row>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link="/notifications/add" />
    </Container>
  );
};

export default Notifications;
