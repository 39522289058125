import ReactPaginate from "react-paginate";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import { Col, Row } from "../../../Grid-system";
import { Add, BodyCard, CardAll, Container } from "../../../components";
import Title from "../../../components/Title/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import { useEffect, useRef } from "react";
const ConferencesAndEvents = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=events_bulletin"
  );
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/v2/events-bulletin${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/v2/events-bulletin"
  );

  return (
    <Container>
      <Row justify="center">
        <Col md={6}>
          <Title
            update="/update-title/events_bulletin"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>
      {data?.data.data.data == 0 ? (
        <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
      ) : (
        ""
      )}
      <Row justify="end">
        {isLoading ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          data?.data.data.data.map((e) => (
            <Col key={e.id} md={6} lg={4}>
              <CardAll
                delete={() => deleteItem(e)}
                update={`/conferences-and-events/${e.id}`}
              >
                <h1 className="text-Brown text-xl font-semibold text-center">
                  {e.title}
                </h1>
                {e.media_type === "VIDEO" ? (
                  e.video.substring(0, 4) === "http" ? (
                    <div className=" h-[230px]">
                      <iframe
                        className="video"
                        title="Youtube player"
                        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                        src={`https://youtube.com/embed/${e.video.substring(
                          32,
                          43
                        )}?autoplay=0`}
                      ></iframe>
                    </div>
                  ) : (
                    <div className="flex items-center h-[230px]">
                      <video
                        className="rounded-2xl object-cover container mx-auto w-full h-full "
                        controls
                      >
                        <source src={`${imgUrl}${e.video}`} />
                      </video>
                    </div>
                  )
                ) : (
                  <div className="pt-4 ">
                    <Swiper className="w-[280px]" slidesPerView={1} spaceBetween={20}>
                      {e.images.map((e) => (
                        <SwiperSlide key={e.id} className="">
                          <img
                            src={imgUrl + e.image}
                            alt=""
                            className="w-[230px] h-[230px] mx-auto rounded-2xl"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
                <p>{parse(e.text)}</p>
                <div className="pt-4">
                  <Swiper className="w-[280px]" spaceBetween={20} slidesPerView={2.2}>
                    {e.slider_images.map((e) => (
                      <SwiperSlide key={e.id} className="">
                        <img
                          src={imgUrl + e.image}
                          alt=""
                          className="w-full h-[100px] rounded-xl"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <div className="flex flex-col items-end h-full">
                  <BodyCard name=" : تاريخ" value={e.date} />
                  <BodyCard name=" : الوقت" value={e.time} />
                </div>
              </CardAll>
            </Col>
          ))
        )}
      </Row>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link="/conferences-and-events/add" />
    </Container>
  );
};

export default ConferencesAndEvents;
