import { useEffect } from "react";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  Imports,
} from "../../components";
import { Col, Row } from "../../Grid-system";

import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
import { imgUrl } from "../../Context/baseUrl";
const UpdateTitle = () => {
  const { id } = useParams();
  const {
    handleSubmit,
    setFormData,
    formData,
    img,
    setImg,
    handleChange,
    loading,
    error,
  } = usePOST();
  const { data: dataTilte } = useFETCH(
    `presidential-candidates/views-info?info=${id}`
  );
  let dataUp = dataTilte?.data.data;
  useEffect(() => {
    setFormData({
      title: dataUp?.title,
      info: id,
    });
  }, [dataUp]);

  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit("admin/presidential-candidates/views-info");
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6}>
            <Imports
              title=": اسم الواجهة"
              name="title"
              value={formData?.title}
              onChange={handleChange}
            />
            <br />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : أيقونة الواجهة
            </h1>
            <AddImage
              clickDelete={() => {
                setImg("");
                setFormData("");
              }}
              name="icon"
              onChange={handleChange}
              newimg={img}
              img={imgUrl + dataTilte?.data.data.icon}
            />
          </Col>
        </Row>
        <div className="text-red-700"> {error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdateTitle;
