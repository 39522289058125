import ReactPaginate from "react-paginate";
import useFetch from "../../Context/useFetch";
import { Container } from "../../components/index";
import Delete from "../../images/Path 2281.png";
import ExportPdf from "../../images/Export.png";
import filter from "../../images/filter (3).png";
import axios from "axios";
import { baseUrl } from "../../Context/baseUrl";
import { useEffect, useRef, useState } from "react";
const AttendanceList = () => {
  const [search, setSearch] = useState("");
  const [awit, setAwit] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [activeExport, setActiveExport] = useState("");
  const [exports, setExports] = useState("");
  const [arrey, setarrey] = useState();
  const { data, loading, error, handlePageClick, deleteItem, deleteError } =
    useFetch(
      `admin/attendants?${sortBy}${search ? `&search=${search}` : ""}`,
      "admin/attendants"
    );
  let pageCount = Math.ceil(data?.data.data.total / data?.data.data.per_page);
  let dataAll = data?.data.data.data;
  const deleteItemThis = async (e) => {
    dataAll = dataAll?.filter((p) => p.id !== e.id);
    deleteItem(e);
  };
  let jjj = arrey;
  useEffect(() => {}, [activeExport]);
  const selectedCheckboxesRef = useRef([]);
  function handleCheckboxChange(event) {
    const { value, checked } = event.target;
    if (checked) {
      selectedCheckboxesRef.current.push(value);
      setarrey(selectedCheckboxesRef.current);
    } else {
      selectedCheckboxesRef.current = selectedCheckboxesRef.current.filter(
        (item) => item !== value
      );
      setarrey(selectedCheckboxesRef.current);
    }
  }
  const fetchFile = () => {
    setAwit(true);
    axios({
      url: `${baseUrl}${`admin/attendants/export/${exports}?${
        activeExport === "checked" ? jjj?.join("&") : activeExport
      }`}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setAwit(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = `file.${exports === "pdf" ? "pdf" : "xlsx"}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
        setAwit(false);
      });
  };
  return (
    <>
      <div className="flex justify-center gap-2 flex-wrap mt-5 my-3">
        <div
          onClick={() => setExports("pdf")}
          className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown  cursor-pointer border border-Brown rounded-xl"
        >
          <select
            className={`mr-5 outline-none border-none ${
              exports === "pdf" ? "" : "hidden"
            }`}
            onChange={(e) => setActiveExport(e.target.value)}
          >
            <option value=""></option>
            <option value="attendants=all">تحديد الكل </option>
            <option value="checked">تحديد العنصر</option>
          </select>
          <span className="mr-5 ">(PDF) تصدير كملف</span>
          <img src={ExportPdf} className="w-[20px]" alt="" />
        </div>
        <div
          onClick={() => setExports("excel")}
          className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl"
        >
          <select
            className={`mr-5 outline-none border-none ${
              exports === "excel" ? "" : "hidden"
            }`}
            onChange={(e) => setActiveExport(e.target.value)}
          >
            <option value=""></option>
            <option value="attendants=all">تحديد الكل </option>
            <option value="checked">تحديد العنصر</option>
          </select>

          <span className="mr-5 ">(Excel) تصدير كملف</span>
          <img src={ExportPdf} className="w-[20px]" alt="" />
        </div>
        <div className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl">
          <input
            type="text"
            className=" outline-none border-none"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="بحث"
          />
          <select
            className="mr-5 outline-none border-none"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="">الكل</option>
            <option value="sort_by=name">اسم الزائر</option>
            <option value="sort_by=phone_number">رقم الهاتف</option>
            <option value="sort_by=party_name">الحزب</option>
            <option value="sort_by=date_of_entry">تاريخ الدخول</option>
            <option value="sort_by=time_of_entry">الساعة</option>
          </select>
          <span>فرز حسب </span>
          <img src={filter} className="w-[20px]" alt="" />
        </div>
      </div>
      <Container>
        {awit ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}
        <div className=" mx-auto  w-full overflow-x-scroll">
          <div className={`text-center ${activeExport ? "" : `hidden`} my-2 `}>
            <button
              onClick={fetchFile}
              className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
            >
              تحميل الملف
            </button>
          </div>
          <table className=" text-2xl text-center w-full ">
            <thead className="text-Brown">
              <tr>
                <td>اسم الزائر</td>
                <td>الحزب</td>
                <td>رقم الهاتف</td>
                <td>تاريخ الدخول</td>
                <td>الساعة</td>
                <td
                  className={`${
                    JSON.parse(window.localStorage.getItem("attendants"))[1] ===
                    "cpanel_cru_admin"
                      ? "hidden"
                      : JSON.parse(
                          window.localStorage.getItem("attendants")
                        )[1] === "cpanel_read_only_admin"
                      ? "hidden"
                      : ""
                  }`}
                >
                  الاحداث
                </td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="border-none" colSpan={7}>
                    <div>
                      <div className="loading"></div>
                    </div>
                  </td>
                </tr>
              ) : (
                dataAll?.map((e) => (
                  <>
                    <tr>
                      <td className="relative">
                        <input
                          type="checkbox"
                          value={"attendants[]=" + e.id}
                          onChange={handleCheckboxChange}
                          className={`${
                            activeExport === "checked" ? "" : "hidden"
                          } absolute top-1 -left-0 w-5 h-5
              `}
                        />
                        {e.name}
                      </td>
                      <td>{e.party_name}</td>
                      <td>{e.phone_number}</td>
                      <td>{e.date_of_entry}</td>
                      <td>{e.time_of_entry}</td>
                      <td
                        className={`${
                          JSON.parse(
                            window.localStorage.getItem("attendants")
                          )[1] === "cpanel_cru_admin"
                            ? "hidden"
                            : JSON.parse(
                                window.localStorage.getItem("attendants")
                              )[1] === "cpanel_read_only_admin"
                            ? "hidden"
                            : ""
                        }`}
                      >
                        <img
                          src={Delete}
                          alt=""
                          className="mx-auto cursor-pointer"
                          onClick={() => deleteItemThis(e)}
                        />
                      </td>
                    </tr>
                  </>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center my-3">
          <ReactPaginate
            className="pagination flex"
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            pageClassName="page-item"
            pageLinkClassName="page-link"
          />
        </div>
      </Container>
    </>
  );
};

export default AttendanceList;
