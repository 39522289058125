import axios from "axios";
import { baseUrl } from "./baseUrl";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useLogin = (url, body) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const naviget = useNavigate();
  // send email and password
  const postLogin = (e) => {
    setError("");
    setLoading(true);
    e.preventDefault();
    axios
      .post(`${baseUrl}${url}`, body)
      .then((auth) => {
        setPages(auth.data.data.admin.views);
        setError("");
        window.localStorage.setItem("token", auth.data.data.token);
        window.localStorage.setItem("name", auth.data.data.admin.name);
        if (window.localStorage.getItem("users")) {
          setLoading(false);
          // naviget("/");
          setLoading(false);
          setError("");
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(Object.values(e?.response.data.errors));
      });
  };
  pages?.map((e) =>
    window.localStorage.setItem(e.view, JSON.stringify([e.is_selected, e.mode]))
  );
 
  if (window.localStorage.getItem("users")) {
    naviget("/");
  }
  return { postLogin, error, loading };
};

const usePost = (url, body, p) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const postRe = (e) => {
    setLoading(true);
    setError("");
    e.preventDefault();
    axios
      .post(`${baseUrl}${url}`, body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((auth) => {
        if (auth) {
          setLoading(false);
          p ? window.history.go(-1) : window.location.reload();
          setError("");
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(Object.values(e?.response.data.errors));
      });
  };

  return { postRe, error, loading };
};
export default usePost;
