import { Border, Container } from "../../../components";
import { NavLink } from "react-router-dom";
import { BsFillDiagram3Fill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { BiFirstAid } from "react-icons/bi";
import { AiFillYoutube, AiOutlineProfile } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";

const OrganizationalWork = () => {
  return (
    <Container>
      <div className="flex justify-center flex-wrap gap-2">
        <div
          className={`border border-Brown  rounded-xl  ${
            JSON.parse(window.localStorage.getItem("forum_program_days")) &&
            JSON.parse(window.localStorage.getItem("forum_program_days"))[0] ===
              false
              ? "hidden"
              : ""
          }`}
        >
          <NavLink
            to={"/organizational-work/programs-Date"}
            className=" p-6 my rounded-s-3xl text-Brown  flex justify-end"
          >
            برنامج المنتدي
            <AiOutlineProfile size={25} className="mx-2" />
          </NavLink>
        </div>
        <div
          className={`border border-Brown  rounded-xl  ${
            JSON.parse(window.localStorage.getItem("seminars")) &&
            JSON.parse(window.localStorage.getItem("seminars"))[0] === false
              ? "hidden"
              : ""
          }`}
        >
          <NavLink
            to={"/organizational-work/seminars"}
            className=" p-6 my rounded-s-3xl text-Brown  flex justify-end"
          >
            الندوات والحاضرين
            <BsFillDiagram3Fill size={25} className="mx-2" />
          </NavLink>
        </div>
        <div
          className={`border border-Brown  rounded-xl  ${
            JSON.parse(window.localStorage.getItem("competent_committees")) &&
            JSON.parse(
              window.localStorage.getItem("competent_committees")
            )[0] === false
              ? "hidden"
              : ""
          }`}
        >
          <NavLink
            to={"/organizational-work/juerrys"}
            className=" p-6 my rounded-s-3xl text-Brown  flex justify-end"
          >
            اللجان المختصة
            <FaUsers size={25} className="mx-2" />
          </NavLink>
        </div>
        <div
          className={`border border-Brown  rounded-xl ${
            JSON.parse(window.localStorage.getItem("audiences")) &&
            JSON.parse(window.localStorage.getItem("audiences"))[0] === false
              ? "hidden"
              : ""
          }`}
        >
          <NavLink
            to={"/organizational-work/assistance"}
            className=" p-6 my rounded-s-3xl text-Brown  flex justify-end"
          >
            الحضور
            <FaUsers size={25} className="mx-2" />
          </NavLink>
        </div>
        <div
          className={`border border-Brown  rounded-xl  ${
            JSON.parse(window.localStorage.getItem("paramedics")) &&
            JSON.parse(window.localStorage.getItem("paramedics"))[0] === false
              ? "hidden"
              : ""
          }`}
        >
          <NavLink
            to={"/organizational-work/firstaid"}
            className=" p-6 my rounded-s-3xl text-Brown  flex justify-end"
          >
            المسعفين
            <BiFirstAid size={25} className="mx-2" />
          </NavLink>
        </div>
        <div
          className={`border border-Brown  rounded-xl  ${
            JSON.parse(window.localStorage.getItem("forum_videos")) &&
            JSON.parse(window.localStorage.getItem("forum_videos"))[0] === false
              ? "hidden"
              : ""
          } `}
        >
          <NavLink
            to={"/organizational-work/meeting"}
            className=" p-6 my rounded-s-3xl  text-Brown  flex justify-end"
          >
            فيديوهات الملتقى
            <AiFillYoutube size={25} className="mx-2" />
          </NavLink>
        </div>
        <div
          className={`border border-Brown  rounded-xl  ${
            JSON.parse(window.localStorage.getItem("hotels")) &&
            JSON.parse(window.localStorage.getItem("hotels"))[0] === false
              ? "hidden"
              : ""
          } `}
        >
          <NavLink
            to={"/organizational-work/hotels"}
            className=" p-6 my rounded-s-3xl  text-Brown  flex justify-end"
          >
            الفنادق
            <IoIosAddCircleOutline size={25} className="mx-2" />
          </NavLink>
        </div>
        <div
          className={`border border-Brown  rounded-xl  ${
            JSON.parse(window.localStorage.getItem("medical_reliefs_orders")) &&
            JSON.parse(
              window.localStorage.getItem("medical_reliefs_orders")
            )[0] === false
              ? "hidden"
              : ""
          }`}
        >
          <NavLink
            to={"/organizational-work/MaydayRequests"}
            className=" p-6 my rounded-s-3xl  text-Brown  flex justify-end"
          >
            طلبات الاستغاثة
            <IoIosAddCircleOutline size={25} className="mx-2" />
          </NavLink>
        </div>
        <div
          className={`border border-Brown  rounded-xl  ${
            JSON.parse(window.localStorage.getItem("attendants")) &&
            JSON.parse(window.localStorage.getItem("attendants"))[0] === false
              ? "hidden"
              : ""
          }`}
        >
          <NavLink
            to={"/organizational-work/AttendanceList"}
            className=" p-6 my rounded-s-3xl  text-Brown  flex justify-end"
          >
            قائمة الحاضرين
            <FaUsers size={25} className="mx-2" />
          </NavLink>
        </div>
      </div>
    </Container>
  );
};

export default OrganizationalWork;
