
import ExportPdf from "../../images/Export.png";
import filter from "../../images/filter (3).png";

function Export() {
  return (
    <div className="flex justify-center gap-2 flex-wrap mt-5 my-3">
      <select className=" py-3 px-4 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl outline-none">
        <option value="">عرض كل المستخدمين</option>
        <option value="">المستخدمين المسكنيين </option>
        <option value="">المستخدمين غير المسكنيين</option>
      </select>
      <div className="flex items-center justify-end py-3 px-6 font-semibold text-Brown  cursor-pointer border border-Brown rounded-xl">
        <span className="mr-5 ">(PDF) تصدير كملف</span>
        <img src={ExportPdf} className="w-[20px]" alt="" />
      </div>
      <div className="flex items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl">
        <span className="mr-5 ">(Excel) تصدير كملف</span>
        <img src={ExportPdf} className="w-[20px]" alt="" />
      </div>
      <div className="flex items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl">
        <span className="mr-5 ">فرز حسب الاسم</span>
        <img src={filter} className="w-[20px]" alt="" />
      </div>
    </div>
  );
}
export default Export;
