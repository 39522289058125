import ReactPaginate from "react-paginate";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { Col, Row } from "../../../Grid-system";
import { BodyCard, Border, CardAll, Container } from "../../../components";
import parse from "html-react-parser";
import Title from "../../../components/Title/Title";
import { Link } from "react-router-dom";
import { imgUrl } from "../../../Context/baseUrl";
import { useEffect, useRef } from "react";
const CV = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data } = useFETCH(`admin/presidential-candidates/profile/biography`);
  const {
    data: dataItems,
    isLoading,
    deleteItem,
  } = useFETCH(
    `admin/presidential-candidates/profile/biography/items${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/presidential-candidates/profile/biography/items"
  );
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=presidential_candidate_profile"
  );

  return (
    <Container>
      <Border>
        <Row justify="center">
          <Col md={5}>
            <Title
              update="/update-title/presidential_candidate_profile"
              title={dataTilte?.data.data.title}
              icon={imgUrl + dataTilte?.data.data.icon}
            />
          </Col>
        </Row>
        <Row justify="center" className="flex-row-reverse">
          <Col md={5}>
            <BodyCard name=" : الأسم" value={data?.data.data.name} />
          </Col>
          <Col md={5}>
            <BodyCard name=" : الديانة" value={data?.data.data.Religion} />
          </Col>
          <Col md={5}>
            <BodyCard name=" : المنصب" value={data?.data.data.position} />
          </Col>
          <Col md={5}>
            <BodyCard
              name=" : المنصب الحزبي"
              value={data?.data.data.Occupation}
            />
          </Col>
          <Col md={5}>
            <BodyCard name=" : الحزب" value={data?.data.data.party} />
          </Col>
          <Col md={5}>
            <BodyCard
              name=" : التوجه الايدولوجي"
              value={data?.data.data.current}
            />
          </Col>
          <Col md={5}>
            <BodyCard
              name=" : تاريخ البداية"
              value={data?.data.data.start_date}
            />
          </Col>
          <Col md={5}>
            <BodyCard
              name=" : تاريخ النهاية"
              value={data?.data.data.end_date}
            />
          </Col>
        </Row>
        <div>
          <Link
            to="/update-CV"
            className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            تعديل
          </Link>
        </div>
      </Border>
      <br />
      <Border>
        {dataItems?.data.data.data == 0 ? (
          <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
        ) : (
          ""
        )}
        <Row justify="center">
          {isLoading ? (
            <div>
              <div className="loading"></div>
            </div>
          ) : (
            dataItems?.data.data.data.map((e) => (
              <Col md={4}>
                <CardAll
                  delete={() => deleteItem(e)}
                  update={`/update/${e.id}`}
                >
                  <h1 className="text-Brown text-xl font-semibold text-center">
                    {e.title}
                  </h1>

                  {parse(e.text)}
                </CardAll>
              </Col>
            ))
          )}
        </Row>
        <div className="flex justify-center my-3">
          <ReactPaginate
            className="pagination flex"
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            ref={a}
            pageCount={Math.ceil(
              dataItems?.data.data.total / dataItems?.data.data.per_page
            )}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            pageClassName="page-item"
            pageLinkClassName="page-link"
          />
        </div>
        <div className="mt-5">
          <Link
            to="/update/biography-items"
            className="m-3 border  border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            أضافة
          </Link>
        </div>
      </Border>
    </Container>
  );
};

export default CV;
