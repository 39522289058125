import { useEffect } from "react";
import { Border, ButtonApp, Container, Imports } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const AddNotifications = () => {
  const { id } = useParams();
  const { data } = useFETCH(id !== "add" && `admin/v2/notifications/${id}`);
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        title: data?.data.data.title,
        body: data?.data.data.body,
        push_time: data?.data.data.push_time,
      });
  }, [data?.data.data]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id !== "add" ? `admin/v2/notifications/${id}` : "admin/v2/notifications"
    );
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={7} className="space-y-4">
            <Imports
              value={formData?.title}
              onChange={handleChange}
              name="title"
              title=" : العنوان"
            />
            <Imports
              value={formData?.body}
              onChange={handleChange}
              name="body"
              title=" : النص"
            />
            <Imports
              type="datetime-local"
              value={formData?.push_time}
              onChange={handleChange}
              name="push_time"
              title=" : تاريخ"
            />
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>
          {id !== "add" ? "تعديل" : "أضافة"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default AddNotifications;
