import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import {
  AiOutlineUser,
  AiOutlineProfile,
  AiOutlineFolderView,
  AiOutlineRightSquare,
} from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { IoIosNotifications } from "react-icons/io";

import { MdCastForEducation } from "react-icons/md";

import { FaUsers } from "react-icons/fa";
import { useContextTitlesHook } from "../../Context/ContextTitles";
import { imgUrl } from "../../Context/baseUrl";

function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const { p1, p2, p3, p4, p5, p6, p7, p8, p9,p10 } = useContextTitlesHook();
  return (
    <div
      className={`${openMenu ? "w-[25%] " : " w-[0%] "} ${
        openMenu
          ? " max-sm:translate-x-0 max-sm:w-[100%] "
          : " max-sm:-translate-x-full"
      }  h-[100vh] max-sm:h-[100%]  sticky bg-Brown max-sm:fixed right-0 top-0 transition-all overflow-y-scroll  py-10 z-[90]`}
    >
      <div
        className={`max-sm:block hidden text-start mx-4 text-2xl font-semibold text-white cursor-pointer sticky top-1`}
        onClick={changeMenu}
      >
        X
      </div>
      <div className="flex  h-full justify-start" style={{ direction: "rtl" }}>
        <ul className="text-black  text-end font-semibold space-y-4">
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("users")) &&
              JSON.parse(window.localStorage.getItem("users"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/users"}
              className="text-white p-2 px-7  my rounded-s-3xl hover:text-Brown hover:bg-white flex justify-start"
            >
              <AiOutlineUser size={25} className="mx-2" />
              المستخدمين الأعضاء
            </NavLink>
          </li>

          <li
            className={`${
              JSON.parse(window.localStorage.getItem("forum_program_days")) &&
              JSON.parse(
                window.localStorage.getItem("forum_program_days")
              )[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/organizational-work"}
              className="text-white p-2 px-7  my rounded-s-3xl hover:text-Brown hover:bg-white flex justify-start"
            >
              <AiOutlineProfile size={25} className="mx-2" />
              أعمال تنظيمية
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("notifications")) &&
              JSON.parse(window.localStorage.getItem("notifications"))[0] ===
                false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/Notifications"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <IoIosNotifications size={25} className="mx-2" />
              الأشعارات
            </NavLink>
          </li>


          <li
            className={`${
              JSON.parse(
                window.localStorage.getItem("audience_requests")
              ) &&
              JSON.parse(
                window.localStorage.getItem("audience_requests")
              )[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/audience_requests"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p10?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p10?.data.data.title}
            </NavLink>
          </li>





          <li
            className={`${
              JSON.parse(
                window.localStorage.getItem("presidential_candidate")
              ) &&
              JSON.parse(
                window.localStorage.getItem("presidential_candidate")
              )[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/presidential-candidate"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p1?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p1?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("party_info")) &&
              JSON.parse(window.localStorage.getItem("party_info"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/about-the-party"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p2?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p2?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("events_bulletin")) &&
              JSON.parse(window.localStorage.getItem("events_bulletin"))[0] ===
                false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/conferences-and-events"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p5?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p5?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("party_news")) &&
              JSON.parse(window.localStorage.getItem("party_news"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/party-news"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p4?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p4?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("party_list")) &&
              JSON.parse(window.localStorage.getItem("party_list"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/party-list"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p7?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p7?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("join_party_requests")) &&
              JSON.parse(
                window.localStorage.getItem("join_party_requests")
              )[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/party-requests"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p8?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p8?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("provincial_hq")) &&
              JSON.parse(window.localStorage.getItem("provincial_hq"))[0] ===
                false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/provincial-secretariat"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p9?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p9?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("parliamentary_hq")) &&
              JSON.parse(window.localStorage.getItem("parliamentary_hq"))[0] ===
                false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/parliamentary-premises"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p3?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p3?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("central_hq")) &&
              JSON.parse(window.localStorage.getItem("central_hq"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/central-secretariat"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <img
                src={imgUrl + p6?.data.data.icon}
                alt=""
                className="w-7 h-7 rounded-full mx-2"
              />
              {p6?.data.data.title}
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("honesties")) &&
              JSON.parse(window.localStorage.getItem("honesties"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/honesty"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <FaUsers size={25} className="mx-2" />
              الأمانة
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("educational_subjects")) &&
              JSON.parse(
                window.localStorage.getItem("educational_subjects")
              )[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/educational"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <MdCastForEducation size={25} className="mx-2" />
              مواد تثقيفيه
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("memberships")) &&
              JSON.parse(window.localStorage.getItem("memberships"))[0] ===
                false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/Memberships"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <FiUsers size={25} className="mx-2" />
              العضويات
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("roles")) &&
              JSON.parse(window.localStorage.getItem("roles"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/Powers"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <AiOutlineRightSquare size={25} className="mx-2" />
              الصلاحيات
            </NavLink>
          </li>
          <li
            className={`${
              JSON.parse(window.localStorage.getItem("admins")) &&
              JSON.parse(window.localStorage.getItem("admins"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/admin-account"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <AiOutlineFolderView size={25} className="mx-2" />
              حسابات الادمن
            </NavLink>
          </li>
          <li
            className={` ${
              JSON.parse(window.localStorage.getItem("provinces")) &&
              JSON.parse(window.localStorage.getItem("provinces"))[0] === false
                ? "hidden"
                : ""
            } `}
          >
            <NavLink
              to={"/provinces"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <FaUsers size={25} className="mx-2" />
              المحافظات
            </NavLink>
          </li>

          <li className={`pb-10`}>
            <NavLink
              to={"/changePassword"}
              className="text-white p-2 px-7  my rounded-s-3xl  hover:text-Brown hover:bg-white flex justify-start"
            >
              <FaUsers size={25} className="mx-2" />
              تغيير كلمة السر
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
