import ReactPaginate from "react-paginate";
import { useFETCH } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import { Col, Row } from "../../../Grid-system";
import {
  Add,
  BodyCard,
  CardAll,
  Container,
  Imports,
} from "../../../components";
import Title from "../../../components/Title/Title";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";

const ParliamentaryPremises = () => {
  const { filter, setFilter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=parliamentary_hq"
  );
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/v2/parliamentary-hqs${
      filter.get("sort_by") || filter.get("search") || filter.get("page")
        ? "?"
        : ""
    }${filter.get("sort_by") ? "&sort_by=" + filter.get("sort_by") : ""}${
      filter.get("search") ? "&search=" + filter.get("search") : ""
    }${filter.get("page") ? "&page=" + filter.get("page") : ""}`,
    "admin/v2/parliamentary-hqs"
  );

  return (
    <Container>
      {isLoading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Row justify="center">
        <Col md={6}>
          <Title
            update="/update-title/parliamentary_hq"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>
      <Row justify="end" className="items-center gap-4">
        <Col md={5}>
          <Imports
            placeholder="بحث"
            value={filter.get("search")}
            onChange={(e) =>
              setFilter({
                search: e.target.value,
                sort_by: filter.get("sort_by") ? filter.get("sort_by") : "",
                page: filter.get("page") ? filter.get("page") : "",
              })
            }
          />
        </Col>
        <Col md={2}>
          <select
            value={filter.get("sort_by")}
            defaultValue=""
            onChange={(e) =>
              setFilter({
                search: filter.get("search") ? filter.get("search") : "",
                sort_by: e.target.value,
                page: filter.get("page") ? filter.get("page") : "",
              })
            }
            className="outline-none"
          >
            <option value="">الكل</option>
            <option value="name">اسم النائب</option>
            <option value="province">المحافظة</option>
          </select>
        </Col>
      </Row>
      {data?.data.data.data.data == 0 ? (
        <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
      ) : (
        ""
      )}
      <Row justify="end">
        {data?.data.data.data.data.map((e) => (
          <Col key={e.id} md={4}>
            <CardAll
              delete={() => deleteItem(e)}
              update={`/parliamentary-premises/${e.id}`}
            >
              <div className="w-[100px] h-[100px] m-4 overflow-hidden  mx-auto">
                <img
                  src={imgUrl + e.image}
                  alt=""
                  className="w-full h-full bg-black"
                />
              </div>
              <BodyCard name=" : الأسم" value={e.name} />
              <BodyCard name=" : المحافظة" value={e.province} />
              <BodyCard name=" : مسمى الوظيفي" value={e.job_title} />
              <BodyCard
                name=" : اللجنه داخل البرلمان"
                value={e.first_phone_number}
              />
              {/* {e.first_phone_number} */}
              <BodyCard
                name=" : رقم الهاتف الاول"
                value={e.secound_phone_number}
              />
              <BodyCard
                name=" : رقم الهاتف الثاني"
                value={e.third_phone_number}
              />
              <br />
              <div>
                {e.location_link ? (
                  <a
                    href={e.location_link}
                    target="_blank"
                    className="border p-4 border-Brown rounded-2xl text-center block"
                  >
                    عرض الخريط
                  </a>
                ) : (
                  <iframe
                    src={`https://maps.google.com/maps?q=${e.lat},${e.long}&hl=es;&output=embed`}
                    width="100%"
                    height="450"
                    style={{ border: "0" }}
                    loading="lazy"
                  ></iframe>
                )}
              </div>
            </CardAll>
          </Col>
        ))}
      </Row>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            data?.data.data.data.total / data?.data.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link="/parliamentary-premises/add" />
    </Container>
  );
};

export default ParliamentaryPremises;
const useFilter = () => {
  const [filter, setFilter] = useSearchParams();
  const handlePageClick = (event) => {
    setFilter({
      page: event.selected + 1,
      search: filter.get("search") ? filter.get("search") : "",
      sort_by: filter.get("sort_by") ? filter.get("sort_by") : "",
    });
  };
  return { filter, handlePageClick, setFilter };
};
