import { Link } from "react-router-dom";
import { Col, Row } from "../../../Grid-system";
import { Add, CardAll, Container } from "../../../components";
import Title from "../../../components/Title/Title";
import { imgUrl } from "../../../Context/baseUrl";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import ReactPaginate from "react-paginate";
import { useEffect, useRef } from "react";

// الامانة المحافظات
const ProvincialSecretariat = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=provincial_hq"
  );
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/v2/provincial-hqs${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/v2/provincial-hqs"
  );
  return (
    <Container>
      <Row justify="center">
        <Col md={5}>
          <Title
            update="/update-title/provincial_hq"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>

      <Row justify="end">
        {isLoading ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          data?.data.data.data.data.map((e) => (
            <Col key={e.id} xs={6} md={4} lg={3}>
              <CardAll
                delete={() => deleteItem(e)}
                update={`/provincial-secretariat/u/${e.id}`}
              >
                <Link to={`/provincial-secretariat/${e.id}/page2`}>
                  {e.name}
                </Link>
              </CardAll>
            </Col>
          ))
        )}
      </Row>
      {data?.data.data.data.data == 0 ? (
        <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
      ) : (
        ""
      )}
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            data?.data.data.data.total / data?.data.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link="/provincial-secretariat/u/add" />
    </Container>
  );
};

export default ProvincialSecretariat;
