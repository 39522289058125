import Title from "../../../components/Title/Title";
import { Link } from "react-router-dom";
import { CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import "swiper/css";
import ReactPaginate from "react-paginate";
import { useEffect, useRef } from "react";
const AboutTheParty = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();

  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data, isLoading } = useFETCH("admin/v2/party-info");
  const { data: dataItems, deleteItem } = useFETCH(
    `admin/v2/party-info/items${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/v2/party-info/items"
  );
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=party_info"
  );

  return (
    <>
      {isLoading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        <Container>
          <Row justify="center">
            <Col md={5}>
              <Title
                update="/update-title/party_info"
                title={dataTilte?.data.data.title}
                icon={imgUrl + dataTilte?.data.data.icon}
              />
            </Col>
          </Row>
          <Row justify="center" className="text-center">
            <Col md={9} className="border border-Brown rounded-xl py-4 ">
              <div className="w-[150px] h-[150px] m-4 overflow-hidden rounded-full mx-auto">
                <img
                  src={imgUrl + data?.data.data.data.image}
                  alt=""
                  className="w-full h-full bg-black"
                />
              </div>
              <p> {parse(data?.data.data.data.text_under_image)}</p>
              <h1 className="text-Brown text-xl font-semibold text-center">
                {data?.data.data.data.title1}
              </h1>
              <p>
                <p> {parse(data?.data.data.data.text_under_title1)}</p>
              </p>
              <Swiper
                className="w-[100vh]"
                slidesPerView={3}
                spaceBetween={20}
                breakpoints={{
                  992: {
                    slidesPerView: 3,
                  },
                  420: {
                    slidesPerView: 2,
                  },
                  0: {
                    slidesPerView: 1.2,
                  },
                }}
              >
                {data?.data.data.data.images.map((e) => (
                  <SwiperSlide key={e.id} className="">
                    <img
                      src={imgUrl + e.image}
                      alt=""
                      className="w-full h-[150px] rounded-2xl"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <h1 className="text-Brown text-xl font-semibold text-center">
                {data?.data.data.data.title2}
              </h1>
              <p> {parse(data?.data.data.data.text_under_title2)}</p>
              <div className="text-start my-4">
                <Link
                  to="/update-about-the-party2"
                  className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
                >
                  تعديل
                </Link>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col md={5}>
              <Title
                iconsShow={false}
                update="/update-title-sub/party-info"
                title={data?.data.data.data.section_name}
              />
            </Col>
          </Row>

          {dataItems?.data.data.data == 0 ? (
            <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
          ) : (
            ""
          )}
          <Row justify="end">
            {dataItems?.data.data.data.map((e) => (
              <Col md={6} lg={4}>
                <CardAll
                  delete={() => deleteItem(e)}
                  update={`/update-about-the-party/${e.id}`}
                >
                  <div className="w-[70px] h-[70px] m-4 overflow-hidden rounded-full mx-auto">
                    <img
                      src={imgUrl + e.image}
                      alt=""
                      className="w-full h-full bg-black"
                    />
                  </div>
                  <h1 className="text-Brown text-xl font-semibold text-center">
                    {e.title}
                  </h1>
                  <p> {parse(e.text)}</p>
                </CardAll>
              </Col>
            ))}
          </Row>
          <div className="flex justify-center my-3">
            <ReactPaginate
              className="pagination flex"
              previousLabel=""
              nextLabel=""
              breakLabel="..."
              ref={a}
              pageCount={Math.ceil(
                dataItems?.data.data.total / dataItems?.data.data.per_page
              )}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              pageClassName="page-item"
              pageLinkClassName="page-link"
            />
          </div>
          <div className="mt-5">
            <Link
              to="/update-about-the-party/add"
              className="m-3 border  border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
            >
              أضافة
            </Link>
          </div>
        </Container>
      )}
    </>
  );
};

export default AboutTheParty;
