import { useParams } from "react-router-dom";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { useEffect, useState } from "react";
import { imgUrl } from "../../../Context/baseUrl";
import { useContextTitlesHook } from "../../../Context/ContextTitles";
import Map from "../Map";

const AddParliamentaryPremises = () => {
  const { centers } = useContextTitlesHook();
  const { id } = useParams();
  const {
    handleSubmit,
    setFormData,
    formData,
    img,
    setImg,
    handleChange,
    loading,
    error,
  } = usePOST();
  const { data: provincesda } = useFETCH(`admin/provinces`);
  const { data } = useFETCH(id !== "add" && `admin/v2/parliamentary-hqs/${id}`);
  let dataUp = data?.data.data;
  useEffect(() => {
    id !== "add"
      ? setFormData({
          ...formData,
          name: dataUp?.name,
          province_id: dataUp?.province_id,
          job_title: dataUp?.job_title,
          first_phone_number: dataUp?.first_phone_number,
          secound_phone_number: dataUp?.secound_phone_number,
          third_phone_number: dataUp?.third_phone_number,
          long: centers[1] || dataUp?.long,
          lat: centers[0] || dataUp?.lat,
          location_link: dataUp?.location_link,
          location_mode: active,
        })
      : setFormData({
          ...formData,
          long: centers[1] || dataUp?.long,
          lat: centers[0] || dataUp?.lat,
        });
  }, [dataUp, centers]);
  useEffect(() => {
    setActive(
      id !== "add" && (dataUp?.location_link === null ? "GPS" : "LINK")
    );
  }, [dataUp]);

  const [active, setActive] = useState("");

  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add"
        ? "admin/v2/parliamentary-hqs"
        : `admin/v2/parliamentary-hqs/${id}`
    );
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6} className="space-y-4">
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : الصورة
            </h1>
            <AddImage
              clickDelete={() => {
                setImg("");
                setFormData("");
              }}
              name="image"
              onChange={handleChange}
              newimg={img}
              img={data?.data.data.image && imgUrl + data?.data.data.image}
            />
            <Imports
              value={formData?.name}
              onChange={handleChange}
              name="name"
              title=" : الأسم"
            />
            <div className="flex flex-row-reverse -mr-4">
              <pre className="flex items-center border-b py-3 font-semibold text-Brown border-Brown -ml-4 justify-start  max-md:p-0">
                : المحافظة
              </pre>
              <select
                name="province_id"
                value={formData?.province_id}
                onChange={handleChange}
                className="outline-none mr-4 border-b border-Brown w-full"
              >
                <option value="0"></option>
                {provincesda?.data.data.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </div>

            <Imports
              value={formData?.job_title}
              onChange={handleChange}
              name="job_title"
              title=" : مسمى الوظيفي "
            />
            <Imports
              value={formData?.first_phone_number}
              onChange={handleChange}
              name="first_phone_number"
              title=" : رقم الاول"
            />
            <Imports
              value={formData?.secound_phone_number}
              onChange={handleChange}
              name="secound_phone_number"
              title=" : رقم الثاني"
            />
            <Imports
              value={formData?.third_phone_number}
              onChange={handleChange}
              name="third_phone_number"
              title=" : رقم الثالث"
            />
            <br />
            <div className="flex justify-center items-center gap-10">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="location_mode"
                  value={"GPS"}
                  onChange={handleChange}
                  onClick={(e) => setActive("GPS")}
                />
                <pre>تحديد الموقع على الخريطة </pre>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="location_mode"
                  value={"LINK"}
                  onChange={handleChange}
                  onClick={(e) => setActive("LINK")}
                />
                <pre> رابط الموقع</pre>
              </div>
            </div>
            {active === "LINK" && (
              <Imports
                title=" : رابط الموقع"
                name="location_link"
                value={formData?.location_link}
                onChange={handleChange}
              />
            )}
            {active === "GPS" && <Map />}
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>
          {id !== "add" ? "تعديل" : "أضافة"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default AddParliamentaryPremises;
