import {
  Add,
  BodyCard,
  Border,
  CardAll,
  Container,
  Error,
} from "../../components/index";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import useFetch from "../../Context/useFetch";
import ExportPdf from "../../images/Export.png";
import filter from "../../images/filter (3).png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../Context/baseUrl";
const Programs = () => {
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [awit, setAwit] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [activeExport, setActiveExport] = useState("");
  const [exports, setExports] = useState("");
  const [arrey, setarrey] = useState();
  const { data: dataCompetent } = useFetch(
    "admin/competent-committees?paginate=none"
  );
  const { data, loading, error, handlePageClick, deleteItem, deleteError } =
    useFetch(
      `admin/forum-program-days/${id}/lectures?${sortBy}${
        search ? `&search=${search}` : ""
      }`,
      "admin/forum-program-days/lectures"
    );
  let pageCount = Math.ceil(
    data?.data.data.forum_program_day_lectures.total /
      data?.data.data.forum_program_day_lectures.per_page
  );
  let dataAll = data?.data.data.forum_program_day_lectures.data;
  let jjj = arrey;
  useEffect(() => {}, [activeExport]);
  const selectedCheckboxesRef = useRef([]);
  function handleCheckboxChange(event) {
    const { value, checked } = event.target;
    if (checked) {
      selectedCheckboxesRef.current.push(value);
      setarrey(selectedCheckboxesRef.current);
    } else {
      selectedCheckboxesRef.current = selectedCheckboxesRef.current.filter(
        (item) => item !== value
      );
      setarrey(selectedCheckboxesRef.current);
    }
  }
  const fetchFile = () => {
    setAwit(true);
    axios({
      url: `${baseUrl}${`admin/forum-program-days/${id}/lectures/export/${exports}?${
        activeExport === "checked" ? jjj?.join("&") : activeExport
      }`}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setAwit(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = `file.${exports === "pdf" ? "pdf" : "xlsx"}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
        setAwit(false);
      });
  };
  return (
    <>
      <div className="flex justify-center gap-2 flex-wrap mt-5 my-3">
        <div
          onClick={() => setExports("pdf")}
          className="flex items-center justify-end py-3 px-6 font-semibold text-Brown  cursor-pointer border border-Brown rounded-xl"
        >
          <select
            className={`mr-5 max-sm:flex-col-reverse  outline-none border-none ${
              exports === "pdf" ? "" : "hidden"
            }`}
            onChange={(e) => setActiveExport(e.target.value)}
          >
            <option value=""></option>
            <option value="lectures=all">تحديد الكل </option>
            <option value="checked">تحديد العنصر</option>
          </select>
          <span className="mr-5 ">(PDF) تصدير كملف</span>
          <img src={ExportPdf} className="w-[20px]" alt="" />
        </div>
        <div
          onClick={() => setExports("excel")}
          className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl"
        >
          <select
            className={`mr-5 outline-none border-none ${
              exports === "excel" ? "" : "hidden"
            }`}
            onChange={(e) => setActiveExport(e.target.value)}
          >
            <option value=""></option>
            <option value="lectures=all">تحديد الكل </option>
            <option value="checked">تحديد العنصر</option>
          </select>

          <span className="mr-5 ">(Excel) تصدير كملف</span>
          <img src={ExportPdf} className="w-[20px]" alt="" />
        </div>
        <div className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl">
          {sortBy === "sort_by=competent_committee_id" ? (
            <>
              <select
                className="outline-none "
                id=""
                onChange={(e) => setSearch(e.target.value)}
                placeholder="بحث"
              >
                <option></option>
                {dataCompetent?.data.data.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <input
              type="text"
              className=" outline-none border-none"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="بحث"
            />
          )}
          <select
            className="mr-5 outline-none border-none"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="">الكل</option>
            <option value="sort_by=start_time">من</option>
            <option value="sort_by=end_time">الى</option>
            <option value="sort_by=lecturer">المحاضر</option>
            <option value="sort_by=content">المحتوى</option>
            <option value="sort_by=competent_committee_id">
              اللجنة المختصة
            </option>
          </select>
          <span>فرز حسب </span>
          <img src={filter} className="w-[20px]" alt="" />
        </div>
      </div>
      <Error error={error} onClick={deleteError} />
      <Container>
        {awit ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}
        <div className={`text-center ${activeExport ? "" : "hidden"} my-2 `}>
          <button
            onClick={fetchFile}
            className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            تحميل الملف
          </button>
        </div>
        <div className={`text-center flex justify-center my-3`}>
          <Border>
            <BodyCard value={data?.data.data.day_info.day} name=":اليوم" />
            <BodyCard value={data?.data.data.day_info.date} name=":التاريخ" />
          </Border>
        </div>
        <div className="flex justify-center flex-wrap gap-3 ">
          {dataAll == 0 ? (
            <h1 className="text-center text-2xl">لا يوجد بيانات</h1>
          ) : (
            ""
          )}
          {loading ? (
            <div>
              <div className="loading"></div>
            </div>
          ) : (
            dataAll?.map((e) => (
              <>
                <CardAll
                  check="forum_program_days_lectures"
                  update={`/organizational-work/programs-Date/${id}/${e.id}`}
                  delete={() => deleteItem(e)}
                  key={e.id}
                >
                  <input
                    type="checkbox"
                    value={"lectures[]=" + e.id}
                    onChange={handleCheckboxChange}
                    className={`${
                      activeExport === "checked" ? "" : "hidden"
                    } absolute -top-3 -left-1 w-5 h-5
          `}
                  />
                  <div className="text-end space-y-2">
                    <BodyCard value={e.from} name=":من " />
                    <BodyCard value={e.to} name=":الى" />
                    <BodyCard value={e.content} name=":المحتوى" />
                    <BodyCard
                      value={e.competent_committee}
                      name=":اللجنة المختصة"
                    />
                    <BodyCard value={e.lecturer} name=":المحاضر" />
                    <BodyCard
                      value={e.notify_before_time}
                      name=":يرسل قبل الوقت"
                    />
                  </div>
                </CardAll>
              </>
            ))
          )}
        </div>
        <div className="flex justify-center my-3">
          <ReactPaginate
            className="pagination flex"
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            pageClassName="page-item"
            pageLinkClassName="page-link"
          />
        </div>
        <Add
          check="forum_program_lectures_lectures"
          link={`/organizational-work/programs-Date/${id}/create-programs`}
        />
      </Container>
    </>
  );
};

export default Programs;
