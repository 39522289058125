import { useEffect } from "react";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
const UpdatePresidentialCandidate = () => {
  const {
    handleSubmit,
    setFormData,
    formData,
    img,
    setImg,
    handleChange,
    loading,
    error,
  } = usePOST();
  const { data: dataTilte } = useFETCH(`admin/presidential-candidates/profile`);
  let dataUp = dataTilte?.data.data.profile;
  useEffect(() => {
    setFormData({
      name: dataUp?.name,
      position: dataUp?.position,
    });
  }, [dataUp]);

  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit("admin/presidential-candidates/profile");
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6} className="space-y-4">
            <Imports
              value={formData?.name}
              onChange={handleChange}
              name="name"
              title=" : الاسم"
            />
            <Imports
              value={formData?.position}
              onChange={handleChange}
              name="position"
              title=" : المنصب"
            />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : صورة
            </h1>
            <AddImage
              clickDelete={() => {
                setImg("");
                setFormData("");
              }}
              name="image"
              onChange={handleChange}
              newimg={img}
              img={imgUrl + dataTilte?.data.data.profile.image}
            />
          </Col>
        </Row>
        <div className="text-red-700"> {error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdatePresidentialCandidate;
