import { useEffect, useState } from "react";
import {
  Border,
  Button,
  ButtonApp,
  Container,
  Imports,
} from "../../components/index";
import useFetch from "../../Context/useFetch";
import { useParams } from "react-router-dom";
import { imgUrl } from "../../Context/baseUrl";
import userPhoto from "../../images/user.jpeg";
import { BiHide, BiShow } from "react-icons/bi";
import { Col, Row } from "../../Grid-system";
import { usePOST } from "../../APIs/useMyAPI";

const CreateAccount = () => {
  const { id } = useParams();
  const [biShow, setBiShow] = useState(false);
  const [conPassword, setConPassword] = useState("");
  const {
    handleSubmit,
    setFormData,
    formData,
    handleChange,
    imgs,
    setImgss,
    setImgs,
    handleChangeArray,
    loading,
    setError,
    error,
  } = usePOST();
  const { data } = useFetch("admin/hotels?paginate=none");
  const { data: dataRooms } = useFetch(
    `admin/hotels/${formData?.hotel_id}/rooms?housing=1`
  );
  const { data: dataRole } = useFetch(`admin/memberships`);
  const { data: provincesda } = useFetch(`admin/provinces`);
  const { data: honesties } = useFetch(`admin/v2/honesties`);
  const { data: datas } = useFetch(`admin/users/${id}`);
  let dataAll = datas?.data.data;

  useEffect(() => {
    !isNaN(id) &&
      setFormData({
        ...formData,
        name: dataAll?.name,
        username: dataAll?.username,
        job: dataAll?.job,
        age: dataAll?.age,
        phone_number: dataAll?.phone_number,
        email: dataAll?.email,
        membership_id: dataAll?.membership_id,
        hotel_id: dataAll?.hotel_id || "",
        party_name: dataAll?.party_name,
        location: dataAll?.location,
        province: dataAll?.province,
        membership_number: dataAll?.membership_number,
        honesty: dataAll?.honesty,
        room_id: dataAll?.room_id,
        honesty_id: dataAll?.honesty_id,
        honesty_job_title: dataAll?.honesty_job_title,
      });
  }, [dataAll]);
  const submitDisplay = (e) => {
    e.preventDefault();
    if (formData?.password === conPassword) {
      handleSubmit(isNaN(id) ? "admin/users" : `admin/users/${id}`);
    } else {
      setError("كلمة السر غير متطابقين");
    }
  };


  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row className="flex-row-reverse">
          <Col lg={4}>
            <Imports
              name="name"
              value={formData?.name}
              onChange={handleChange}
              title=" : الاسم الثلاثي*"
            />
          </Col>
          <Col lg={4}>
            <Imports
              name="username"
              value={formData?.username}
              onChange={handleChange}
              title=":اسم المستخدم*"
            />
          </Col>

          <Col lg={4}>
            <Imports
              value={formData?.national_id}
              name="national_id"
              onChange={handleChange}
              title=" : الرقم القومي"
            />
          </Col>

          <Col lg={4}>
            <Imports
              name="job"
              value={formData?.job}
              onChange={handleChange}
              title=" :الوظيفة*"
            />
          </Col>
          <Col lg={4}>
            <Imports
              name="age"
              value={formData?.age}
              onChange={handleChange}
              title=" :العمر*"
            />
          </Col>
          <Col lg={4}>
            <Imports
              name="phone_number"
              value={formData?.phone_number}
              onChange={handleChange}
              title=":الهاتف*"
            />
          </Col>
          <Col lg={4}>
            <Imports
              name="email"
              value={formData?.email}
              type="email"
              onChange={handleChange}
              title=":بريد الكتروني*"
            />
          </Col>
          <Col lg={4}>
            <div className="relative w-full">
              <Imports
                name="password"
                value={formData?.password}
                type={biShow ? "text" : "password"}
                onChange={handleChange}
                title=":كلمة السر*"
              />
              {biShow ? (
                <BiShow
                  onClick={() => setBiShow(false)}
                  size={20}
                  className="absolute left-0 top-5 cursor-pointer"
                />
              ) : (
                <BiHide
                  onClick={() => setBiShow(true)}
                  size={20}
                  className="absolute left-0 top-5 cursor-pointer"
                />
              )}
            </div>
          </Col>
          <Col lg={4}>
            <div className="relative w-full">
              <Imports
                name="password"
                value={conPassword}
                type={biShow ? "text" : "password"}
                onChange={(e) => setConPassword(e.target.value)}
                title=": تأكيد كلمة السر*"
              />
              {biShow ? (
                <BiShow
                  onClick={() => setBiShow(false)}
                  size={20}
                  className="absolute left-0 top-5 cursor-pointer"
                />
              ) : (
                <BiHide
                  onClick={() => setBiShow(true)}
                  size={20}
                  className="absolute left-0 top-5 cursor-pointer"
                />
              )}
            </div>
          </Col>
          <Col lg={4}>
            <Imports
              name="party_name"
              value={formData?.party_name}
              onChange={handleChange}
              title=":اسم الحزب"
            />
          </Col>
          <Col lg={4}>
            <Imports
              name="location"
              value={formData?.location}
              onChange={handleChange}
              title=":عنوان السكن"
            />
          </Col>
          <Col lg={4} className="flex flex-row-reverse">
            <pre className="flex  items-center border-b  font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
              :اسم الفندق
            </pre>
            <select
              defaultValue=""
              name="hotel_id"
              value={formData?.hotel_id}
              className="outline-none py-3 border-b mr-4 w-full border-Brown"
              onChange={handleChange}
            >
              <option value=""></option>
              {data?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </Col>
          <Col lg={4} className="flex flex-row-reverse">
            <pre className="flex items-center border-b  font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
              :رقم الغرفة
            </pre>
            <select
              defaultValue=""
              className="outline-none py-3 border-b mr-4 w-full border-Brown"
              id=""
              name="room_id"
              value={formData?.room_id}
              onChange={handleChange}
            >
              <option value=""></option>
              {dataRooms?.data.data?.map((e) => (
                <option key={e.id} value={e.id}>
                  رقم الغرفة {e.number} || السعة {e.number_of_reserved_places} /
                  {e.number_of_places}
                </option>
              ))}
            </select>
          </Col>
          <Col lg={4} className="flex flex-row-reverse">
            <pre className="flex items-center border-b py-3 font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
              :نوع العضوية*
            </pre>
            <select
              defaultValue="0"
              name="membership_id"
              value={formData?.membership_id}
              className="outline-none mr-4 border-b border-Brown w-full"
              onChange={handleChange}
            >
              <option value="0"></option>
              {dataRole?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </Col>
          <Col lg={4} className="flex flex-row-reverse">
            <pre className="flex items-center border-b py-3 font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
              : المحافظة
            </pre>
            <select
              name="province_id"
              value={formData?.province_id}
              className="outline-none mr-4 border-b border-Brown w-full"
              onChange={handleChange}
            >
              <option value="0"></option>
              {provincesda?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </Col>
          <Col lg={4} className="flex flex-row-reverse">
            <pre className="flex items-center border-b py-3 font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
              : الأمانة
            </pre>
            <select
              name="honesty_id"
              value={formData?.honesty_id}
              className="outline-none mr-4 border-b border-Brown w-full"
              onChange={handleChange}
            >
              <option value="0"></option>
              {honesties?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </Col>
          <Col lg={8}>
            <Imports
              name="honesty_job_title"
              value={formData?.honesty_job_title}
              onChange={handleChange}
              title=" : المسمى الوظيفي داخل الأمانة"
            />
          </Col>
          <Col lg={4}>
            <Imports
              name="membership_number"
              value={formData?.membership_number}
              onChange={handleChange}
              title=": رقم العضوية"
            />
          </Col>
          <Col lg={4}>
            <Imports
              name="cv_file"
              type="file"
              onChange={handleChange}
              title=" : CV رفع ملف "
            />
            {/* <div>{formData?.cv_file.name}</div> */}
          </Col>
          <div className="flex justify-center flex-wrap gap-5">
            <div className="border-dashed border-[2px] border-Brown rounded-lg ">
              <div className="flex items-center justify-center ">
                <input
                  id="file-input"
                  type="file"
                  name="image"
                  onChange={handleChangeArray}
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple
                />
                <button
                  className="px-20 py-10"
                  onClick={() => document.getElementById("file-input").click()}
                >
                  أضف صورة الشخصية
                </button>
                <div className=" flex flex-row-reverse">
                  {dataAll?.image ? (
                    <div className="relative">
                      <img
                        src={`${imgUrl + dataAll?.image}`}
                        alt=""
                        className="w-[300px] h-[220px] mx-auto"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {imgs.length !== 0 && (
                    <div className="relative">
                      <div
                        onClick={() => {
                          setImgss("");
                          setImgs("");
                        }}
                        className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                      >
                        X
                      </div>
                      <img
                        className="w-[300px] h-[220px] mx-auto"
                        src={imgs ? imgs : userPhoto}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
        <div className="text-red-500">
          <div>{error}</div>
        </div>
        {isNaN(id) ? (
          <Button click={submitDisplay} />
        ) : (
          <ButtonApp onClick={submitDisplay}>
            <span className="px-10">تعديل</span>
          </ButtonApp>
        )}
      </Border>
    </Container>
  );
};

export default CreateAccount;
