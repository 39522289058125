import { BodyCard, CardAll, Container } from "../../components/index";
import { useParams } from "react-router-dom";
import useFetch from "../../Context/useFetch";
import { imgUrl } from "../../Context/baseUrl";
import imgUser from "../../images/user.jpeg";
import { Col, Row } from "../../Grid-system";
const UsersTwo = () => {
  const { id } = useParams();
  const { data, deleteItem } = useFetch(`admin/users/${id}`, `admin/users`);
  let dataAll = data?.data.data;
  return (
    <Container>
      <CardAll
        delete={() => deleteItem(dataAll)}
        update={`/users/account/${dataAll?.id}`}
      >
        <div className="max-sm:m-0 -mt-[30px]">
          <div className="flex justify-around max-md:flex-col gap-10">
            <img
              src={`${imgUrl}${dataAll?.qrimage}`}
              alt=""
              className="w-[170px] h-[170px] mx-auto"
            />
            <img
              src={dataAll?.image ? imgUrl + dataAll?.image : imgUser}
              alt=""
              className="w-[130px] h-[130px] mx-auto rounded-full"
            />
          </div>
          <div className="my-4">
            <Row justify="end">
              <Col lg={4}>
                <BodyCard value={dataAll?.username} name=":اسم المستخدم" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.name} name=": الاسم الثلاثي" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.name} name=": الرقم القومي" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.job} name=":الوظيفة" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.age} name=":العمر" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.phone_number} name=":الهاتف" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.email} name=":الإيميل" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.party_name} name=":اسم الحزب" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.role} name=":العضوية" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.location} name=": عنوان السكن" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.province} name=": المحافظة" />
              </Col>
              <Col lg={4}>
                <BodyCard
                  value={dataAll?.membership_number}
                  name=": رقم العضوية"
                />
              </Col>

              <Col lg={4}>
                <BodyCard value={dataAll?.honesty} name=": الأمانة" />
              </Col>
              <Col lg={4}>
                <BodyCard value={dataAll?.national_id} name=": رقم القومي" />
              </Col>
              <Col lg={4}>
                <BodyCard
                  link={imgUrl + dataAll?.cv_file}
                  value={dataAll?.cv_file}
                  name=": cv ملف"
                />
              </Col>
              <Col lg={4}>
                <BodyCard
                  value={dataAll?.is_active ? "مفعل" : "غير مفغل"}
                  name=": حالة"
                />
              </Col>
              <Col lg={7}>
                <BodyCard
                  value={dataAll?.honesty_job_title}
                  name=": المسمى الوظيفي داخل الأمانة"
                />
              </Col>
            </Row>
          </div>
        </div>
      </CardAll>
    </Container>
  );
};

export default UsersTwo;
