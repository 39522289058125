import { createContext, useContext, useState } from "react";
import { useFETCH } from "../APIs/useMyAPI";

const ContextTitles = createContext({});

const ContextTitlesProvider = ({ children }) => {
  const [centers, setCenters] = useState("");
  const { data: p1 } = useFETCH(
    "admin/presidential-candidates/views-info?info=presidential_candidate"
  );
  const { data: p2 } = useFETCH(
    "admin/presidential-candidates/views-info?info=party_info"
  );
  const { data: p3 } = useFETCH(
    "admin/presidential-candidates/views-info?info=parliamentary_hq"
  );
  const { data: p4 } = useFETCH(
    "admin/presidential-candidates/views-info?info=party_news"
  );
  const { data: p5 } = useFETCH(
    "admin/presidential-candidates/views-info?info=events_bulletin"
  );
  const { data: p6 } = useFETCH(
    "admin/presidential-candidates/views-info?info=central_hq"
  );
  const { data: p7 } = useFETCH(
    "admin/presidential-candidates/views-info?info=party_list"
  );
  const { data: p8 } = useFETCH(
    "admin/presidential-candidates/views-info?info=join_party_requests"
  );
  const { data: p9 } = useFETCH(
    "admin/presidential-candidates/views-info?info=provincial_hq"
  );
  const { data: p10 } = useFETCH(
    "admin/presidential-candidates/views-info?info=audience_requests"
  );
  return (
    <ContextTitles.Provider
      value={{
        setCenters,
        centers,
        p1,
        p2,
        p3,
        p4,
        p5,
        p6,
        p7,
        p8,
        p9,
        p10,
      }}
    >
      {children}
    </ContextTitles.Provider>
  );
};

export default ContextTitlesProvider;

export const useContextTitlesHook = () => {
  return useContext(ContextTitles);
};
