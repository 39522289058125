import { Add, BodyCard, CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import Title from "../../../components/Title/Title";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";
import { useEffect, useRef } from "react";

const Videos = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/presidential-candidates/videos${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/presidential-candidates/videos"
  );
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=presidential_candidate_videos"
  );

  return (
    <Container>
      <Row justify="center">
        <Col md={5}>
          <Title
            update="/update-title/presidential_candidate_videos"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>
      {data?.data.data.data == 0 ? (
        <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
      ) : (
        ""
      )}
      <Row justify="end">
        {isLoading ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          data?.data.data.data.map((e) => (
            <Col md={6} lg={4} key={e.id}>
              <CardAll
                delete={() => deleteItem(e)}
                update={`/update-videos/${e.id}`}
              >
                <h1 className="text-center font-semibold text-Brown text-xl mb-2">
                  {e.title}
                </h1>
                {e.video.substring(0, 4) === "http" ? (
                  <div className=" h-[230px]">
                    <iframe
                      className="video"
                      title="Youtube player"
                      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                      src={`https://youtube.com/embed/${e.video.substring(
                        32,
                        43
                      )}?autoplay=0`}
                    ></iframe>
                  </div>
                ) : (
                  <div className="flex items-center h-[230px]">
                    <video
                      className="rounded-2xl object-cover container mx-auto w-full h-full "
                      controls
                    >
                      <source src={`${imgUrl}${e.video}`} />
                    </video>
                  </div>
                )}
                {parse(e.description)}
                <div className="">
                  <BodyCard name=" : تاريخ" value={e.date} />
                  <BodyCard name=" : الوقت" value={e.time} />
                </div>
              </CardAll>
            </Col>
          ))
        )}
      </Row>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link="/update-videos/add" />
    </Container>
  );
};

export default Videos;
