import JoditEditor from "jodit-react";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";

import { useEffect, useRef, useState } from "react";
import { imgUrl } from "../../../Context/baseUrl";

const UpdateAboutTheParty2 = () => {
  const editor = useRef(null);

  const [showDelete, setShowDelete] = useState(false);
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");

  const { data } = useFETCH(`admin/v2/party-info`);
  const {
    handleSubmit,
    setFormData,
    formData,
    handleChange,
    img,
    loading,
    setImg,
    error,
    handleChangeArray,
    deleteCheckArray,
    imgs,
    setImgs,
    setImgss,
    imgss,
  } = usePOST();
  useEffect(() => {
    setFormData({
      ...formData,
      title1: formData?.title1 || data?.data.data.data.title1,
      text_under_title1: content2 || data?.data.data.data.text_under_title1,
      title2: formData?.title2 || data?.data.data.data.title2,
      text_under_title2: content3 || data?.data.data.data.text_under_title2,
      text_under_image: content1 || data?.data.data.data.text_under_image,
    });
  }, [data?.data.data, content1, content2, content3]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit("admin/v2/party-info");
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6} className="space-y-4">
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : صورة
            </h1>
            <AddImage
              clickDelete={() => {
                setImg("");
                setFormData("");
              }}
              name="image"
              onChange={handleChange}
              newimg={img}
              img={imgUrl + data?.data.data.data.image}
            />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <JoditEditor
              ref={editor}
              value={data?.data.data.data.text_under_image}
              onChange={(newContent) => setContent1(newContent)}
            />
            <Imports
              title=":  العنوان"
              name="title1"
              value={formData?.title1}
              onChange={handleChange}
            />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <JoditEditor
              ref={editor}
              value={data?.data.data.data.text_under_title1}
              onChange={(newContent) => setContent2(newContent)}
            />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : صور
            </h1>
            <AddImage
              id="slider_images"
              name="slider_images[]"
              onChange={handleChangeArray}
              title="صور"
            />
            <Row>
              {imgs &&
                imgs.map((e, i) => (
                  <Col key={i} md={4}>
                    <div className="relative">
                      <span
                        onClick={() => {
                          let total = [...imgs];
                          let total2 = [...imgss];
                          total.splice(i, 1);
                          total2.splice(i, 1);
                          setImgs(total);
                          setImgss(total2);
                        }}
                        className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer -top-2 rounded-full absolute"
                      >
                        X
                      </span>
                      <img src={e} alt="" className="w-full  rounded-2xl" />
                    </div>
                  </Col>
                ))}
            </Row>

            <Imports
              title=":  العنوان"
              name="title2"
              value={formData?.title2}
              onChange={handleChange}
            />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <JoditEditor
              ref={editor}
              value={data?.data.data.data.text_under_title2}
              onChange={(newContent) => setContent3(newContent)}
            />
          </Col>
        </Row>
        {data?.data.data.data.images && (
          <div
            onClick={() => setShowDelete(!showDelete)}
            className="m-3 border border-Brown w-fit text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            حذف الصور
          </div>
        )}
        <Row justify="center">
          {data?.data.data.data.images &&
            data?.data.data.data.images.map((e) => (
              <Col md={4} lg={3} className="relative">
                {showDelete && (
                  <input
                    type="checkbox"
                    value={e.id}
                    name="slider_images_to_delete_ids[]"
                    onChange={deleteCheckArray}
                    className="absolute w-5 h-5 -top-2 -left-1"
                  />
                )}
                <img
                  src={imgUrl + e.image}
                  alt=""
                  className="h-[250px] w-full"
                />
              </Col>
            ))}
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdateAboutTheParty2;
