import JoditEditor from "jodit-react";
import {
  Border,
  ButtonApp,
  Container,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const UpdatePartyList = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { id } = useParams();

  const { data } = useFETCH(id !== "add" && `admin/v2/party-list/items/${id}`);
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        text: content || data?.data.data.text,
        title1: formData?.title1 || data?.data.data.title1,
        title2: formData?.title2 || data?.data.data.title2,
      });
    id === "add" &&
      setFormData({
        ...formData,
        text: content,
      });
  }, [data?.data.data, content]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id !== "add"
        ? `admin/v2/party-list/items/${id}`
        : "admin/v2/party-list/items"
    );
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6}>
            <Imports
              value={formData?.title1}
              onChange={handleChange}
              name="title1"
              title=" : العنوان 1"
            />
            <Imports
              value={formData?.title2}
              onChange={handleChange}
              name="title2"
              title=" : العنوان 2"
            />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <div>
              <JoditEditor
                ref={editor}
                value={data?.data.data.text}
                onChange={(newContent) => setContent(newContent)}
              />
            </div>
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>
          {id !== "add" ? "تعديل" : "أضافة"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdatePartyList;
