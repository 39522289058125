import { useEffect } from "react";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { Border, ButtonApp, Container, Imports } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useParams } from "react-router-dom";

const AddProvincial = () => {
  const { id } = useParams();
  const { data } = useFETCH(id !== "add" && `admin/v2/provincial-hqs/${id}`);
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  useEffect(() => {
    setFormData({
      name: data?.data.data.name,
    });
  }, [data?.data.data]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? "admin/v2/provincial-hqs" : `admin/v2/provincial-hqs/${id}`
    );
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6}>
            <Imports
              value={formData?.name}
              onChange={handleChange}
              name="name"
              title=" : النص"
            />
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>
          {id !== "add" ? "تعديل" : "أضافة"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default AddProvincial;
