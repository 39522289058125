import { useEffect, useRef, useState } from "react";
import { Border, ButtonApp, Container, Imports } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import JoditEditor from "jodit-react";

const UpdatePresidentialProgramme2 = () => {
  const { id } = useParams();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  const { data } = useFETCH(`presidential-candidates/programme/items/${id}`);
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        name: formData?.name || data?.data.data.name,
        title: formData?.title || data?.data.data.title,
        text: content || data?.data.data.text,
      });
    id === "add" &&
      setFormData({
        ...formData,
        text: content,
      });
  }, [content, data?.data.data]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id !== "add"
        ? `admin/presidential-candidates/programme/items/${id}`
        : "admin/presidential-candidates/programme/items",
      true
    );
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={7}>
            <Imports
              name="name"
              value={formData?.name}
              onChange={handleChange}
              title=" : الاسم"
            />
            <Imports
              name="title"
              value={formData?.title}
              onChange={handleChange}
              title=" : العنوان"
            />
            <br />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <div>
              <JoditEditor
                ref={editor}
                value={data?.data.data.text}
                onChange={(newContent) => setContent(newContent)}
              />
            </div>
          </Col>
        </Row>
        <div className="text-red-700"> {error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdatePresidentialProgramme2;
