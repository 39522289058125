import { useState, useEffect, useRef } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useContextTitlesHook } from "../../Context/ContextTitles";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const Map = ({ latitudes, longitudes }) => {
  const center = {
    lat: latitudes || 26.984019398064216,
    lng: longitudes || 30.547035426835823,
  };
  const { setCenters } = useContextTitlesHook();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCfkdKB1BFRb0TndYiUfBM-6Gst2F7ol6s",
  });

  const [map, setMap] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleMapClick = (event) => {
    const { latLng } = event;
    const latitude = latLng.lat();
    const longitude = latLng.lng();
    setCenters([latitude, longitude]);
    setSelectedLocation({ lat: latitude, lng: longitude });
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMap({
            center: {
              lat: latitude,
              lng: longitude,
            },
            zoom: 8,
          });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          setMap({ center, zoom: 8 });
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setMap({ center, zoom: 10 });
    }
  }, []);

  return isLoaded ? (
    <GoogleMap
      id="map"
      mapContainerStyle={containerStyle}
      center={map && map.center}
      zoom={map && map.zoom}
      onClick={handleMapClick}
    >
      {selectedLocation && (
        <Marker
          position={selectedLocation}
          draggable={true}
          onDragEnd={(e) =>
            setSelectedLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          }
        />
      )}
    </GoogleMap>
  ) : null;
};

export default Map;
