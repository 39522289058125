import React, { useState } from "react";
import { BiTrashAlt } from "react-icons/bi";

const DeleteIcon = ({ onClick, check }) => {
  const [sure, setSure] = useState(false);
  let x = JSON.parse(localStorage.getItem(check || "users")) || "";
  return (
    <>
      <div
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full   h-full top-0 left-0 popup z-40 flex justify-center items-center`}
      >
        <div className="bg-white z-50 rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
          <p className="font-semibold text-3xl text-center py-7">
            هل انت متأكد من حذف هذا العنصر؟
          </p>
          <div className="flex items-end m-5">
            <button
              onClick={onClick}
              className=" border px-10 border-Brown bg-Brown text-white font-semibold  p-3 rounded-xl"
            >
              نعم
            </button>
            <button
              onClick={() => setSure(false)}
              className=" border px-10 border-Brown text-Brown bg-white font-semibold  p-3 rounded-xl ml-5"
            >
              تراجع
            </button>
          </div>
        </div>
      </div>
      <button
        className={`cursor-pointer ${
          x[1] === "cpanel_read_only_admin" ? "hidden" : ""
        }`}
        onClick={() => setSure(true)}
      >
        <BiTrashAlt size={35} color={"rgb(244,63,94)"} />
      </button>
    </>
  );
};

export default DeleteIcon;
