import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";
import { baseUrl } from "../Context/baseUrl";

//!=============================================================> post data login
export const useLOGIN = (initialState) => {
  const [formData, setFormData] = useState(initialState); // data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (url) => {
    setLoading(true);
    setError("");
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }
    axios
      .post(`${baseUrl}${url}`, formDataToSend)
      .then((req) => {
        setLoading(false);
        setError("");
        localStorage.setItem("token", req.data.data.token);
        navigate("/");
      })
      .catch((e) => {
        setError(e.response.data.message);
        setLoading(false);
      });
  };
  //
  return { formData, handleChange, loading, error, handleSubmit };
};
//! =============================================================> start post data all
export const usePOST = (initialState) => {
  const [formData, setFormData] = useState(initialState); //send  data ==> text , image , video , time , file
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [img, setImg] = useState(""); // viwe image doucment
  const [imgs, setImgs] = useState([]); // viwe images array
  const [imgs2, setImgs2] = useState([]); // viwe images array
  const [imgss, setImgss] = useState([]); // send Array images
  const [imgss2, setImgss2] = useState([]); // send Array images
  const [name, setName] = useState(); // send Array images
  const [name2, setName2] = useState(); // send Array images
  const [name3, setName3] = useState(); // send Array images
  const [name4, setName4] = useState(); // send Array images
  const [deleteCheck, setDeleteCheck] = useState([]);
  const [deleteCheck2, setDeleteCheck2] = useState([]);
  const handleChange = (event) => {
    //function change input everything
    const { name, value, type, files, checked } = event.target;
    const newValue =
      type === "file" ? files[0] : type === "checkbox" ? checked : value; //
    type === "file" && setImg(URL.createObjectURL(newValue));
    // let dateTime =
    //   type === "datetime-local"
    //     ? value.substring(0, 10) + " " + value.substring(11) + ":00"
    //     : newValue; ===> type === "datetime-local" ? dateTime : newValue,
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleChangeArray = (event) => {
    //function change input array images
    const { files, name } = event.target;
    let inputFiles = files;
    setName(name);
    for (let i = 0; i < inputFiles.length; i++) {
      setImgss((prevFiles) => [...prevFiles, inputFiles[i]]);
    }
    const imageUrls = Array.from(inputFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setImgs((prevImageUrls) => [...prevImageUrls, ...imageUrls]);
  };
  const handleChangeArray2 = (event) => {
    //function change input array images
    const { files, name } = event.target;
    let inputFiles = files;
    setName2(name);
    for (let i = 0; i < inputFiles.length; i++) {
      setImgss2((prevFiles) => [...prevFiles, inputFiles[i]]);
    }
    const imageUrls = Array.from(inputFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setImgs2((prevImageUrls) => [...prevImageUrls, ...imageUrls]);
  };
  const deleteCheckArray = (e) => {
    const { value, checked, name } = e.target;
    if (checked) {
      setDeleteCheck([...deleteCheck, value]);
    } else {
      setDeleteCheck(deleteCheck.filter((p) => p !== value));
    }
    setName3(name);
  };
  const deleteCheckArray2 = (e) => {
    const { value, checked, name } = e.target;
    if (checked) {
      setDeleteCheck2([...deleteCheck2, value]);
    } else {
      setDeleteCheck2(deleteCheck2.filter((p) => p !== value));
    }
    setName4(name);
  };
  /// **********************function post data all
  const handleSubmit = async (url, route) => {
    setLoading(true);
    setError("");
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }
    for (let i = 0; i < imgss.length; i++) {
      formDataToSend.append(name, imgss[i]);
    }
    for (let i = 0; i < imgss2.length; i++) {
      formDataToSend.append(name2, imgss2[i]);
    }
    for (let i = 0; i < deleteCheck.length; i++) {
      formDataToSend.append(name3, deleteCheck[i]);
    }
    for (let i = 0; i < deleteCheck2.length; i++) {
      formDataToSend.append(name4, deleteCheck2[i]);
    }

    axios
      .post(`${baseUrl}${url}`, formDataToSend, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((req) => {
        setLoading(false);
        setError("");
        route ? console.log() : window.history.go(-1);
        route === 55 ? window.location.reload(false) : console.log();
      })
      .catch((e) => {
        setError(e.response.data.message);
        setLoading(false);
      });
  };
  return {
    formData,
    setFormData,
    handleChange,
    setDeleteCheck2,
    handleChangeArray,
    handleChangeArray2,
    loading,
    error,
    setError,
    handleSubmit,
    img,
    setImg,
    imgs,
    setImgs,
    deleteCheckArray,
    deleteCheckArray2,
    imgss,
    setImgss,
    imgs2,
    imgss2,
    setImgss2,
    setImgs2,
    setName4,
  };
};

//!=============================================================>start fetch data
export const useFETCH = (url, urlDelete) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isErroe, setIsError] = useState("");

  const naviget = useNavigate();
  useEffect(() => {
    axios
      .get(`${baseUrl}${url}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((req) => {
        if (req) {
          setData(req);
          setIsLoading(false);
          setIsError("");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setIsError("خطأ في جلب البيانات");

        if (e.request.status === 401) {
          naviget("/login");
          localStorage.clear();
        }
        if (e.request.status === 403) {
          naviget("/403");
        }
        if (e.request.status === 500) {
          naviget("/500");
        }
      });
  }, [url]);

  //?=============================================================> deleteItem
  const deleteItem = async (e) => {
    setIsLoading(true);
    axios
      .delete(`${baseUrl}${urlDelete || url}/${e.id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((req) => {
        setIsLoading(false);
        setIsError("تم الحذف  بنجاح");
        window.location.reload();
      })
      .catch((e) => {
        setIsLoading(false);
        setIsError(" خطأ في الحذف  حاول مرة آخرى");
      });
  };
  return { data, isLoading, isErroe, deleteItem };
};
//=============================================================> end fetch data

//!=============================================================> pagination or filter or search
export const useFilter = (params) => {
  const [filter, setFilter] = useSearchParams();
  const handlePageClick = (event) => {
    setFilter(params || { page: event.selected + 1 });
  };
  return { filter, handlePageClick, setFilter };
};
//!=============================================================> عدم دخول من غير تسجيل دخول
export const RequireAuth = ({ children }) => {
  if (!window.localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }
  return children;
};
//! =============================================================>عدم فتح صفحة تسجيل دخول اذا مسجل مسيقا
export const RequireLogin = ({ children }) => {
  if (window.localStorage.getItem("token")) {
    return <Navigate to="/" />;
  }
  return children;
};
//!=============================================================> تكبير الصورة
export const handleClick = (event) => {
  event.target.requestFullscreen();
};
