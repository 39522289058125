import ReactPaginate from "react-paginate";
import useFetch from "../../Context/useFetch";
import ExportPdf from "../../images/Export.png";
import filter from "../../images/filter (3).png";
import {
  Add,
  BodyCard,
  CardAll,
  Container,
  Error,
} from "../../components/index";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { baseUrl, imgUrl } from "../../Context/baseUrl";
import imgUser from "../../images/user.jpeg";
import axios from "axios";
import { usePOST } from "../../APIs/useMyAPI";

const Users = () => {
  const [views, setViews] = useState("");
  const [search, setSearch] = useState("");
  const [awit, setAwit] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [activeExport, setActiveExport] = useState("");
  const [exports, setExports] = useState("");
  const [arrey, setarrey] = useState();
  const [active, setActive] = useState();
  const { data, loading, error, handlePageClick, deleteItem, deleteError } =
    useFetch(
      `admin/users?${views}${sortBy}${search ? `&search=${search}` : ``}${
        active && "&" + active
      }`,
      `admin/users`
    );
  let pageCount = Math.ceil(data?.data.data.total / data?.data.data.per_page);
  let dataAll = data?.data.data.data;

  let jjj = arrey;
  useEffect(() => {}, [activeExport]);

  const selectedCheckboxesRef = useRef([]);
  function handleCheckboxChange(event) {
    const { value, checked } = event.target;
    if (checked) {
      selectedCheckboxesRef.current.push(value);
      setarrey(selectedCheckboxesRef.current);
    } else {
      selectedCheckboxesRef.current = selectedCheckboxesRef.current.filter(
        (item) => item !== value
      );
      setarrey(selectedCheckboxesRef.current);
    }
  }

  const fetchFile = () => {
    setAwit(true);
    axios({
      url: `${baseUrl}${`admin/users/export/${exports}?${
        activeExport === "checked" ? jjj?.join("&") : activeExport
      }`}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setAwit(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = `file.${exports === "pdf" ? "pdf" : "xlsx"}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
        setAwit(false);
      });
  };
  const { handleSubmit, loading: lo, setFormData, error: err } = usePOST({});
  const posttt = (e) => {
    handleSubmit(`admin/v2/users/${e}/deactive`, 55);
  };
  const posttts = (e) => {
    handleSubmit(`admin/v2/users/${e}/active`, 55);
  };

  return (
    <>
      <div className="flex justify-center gap-2 flex-wrap mt-5 my-3">
        <select
          onChange={(e) => setViews(e.target.value)}
          className=" text-sm py-3 px-4 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl outline-none"
        >
          <option value="">عرض كل المستخدمين</option>
          <option value="is_housed=1">المستخدمين تم تسكينهم </option>
          <option value="is_housed=0">المستخدمين لم يتم تسكينهم</option>
        </select>
        <div
          onClick={() => setExports("pdf")}
          className="flex  max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown  cursor-pointer border border-Brown rounded-xl"
        >
          <select
            className={`mr-5 max-sm:flex-col-reverse outline-none border-none ${
              exports === "pdf" ? "" : "hidden"
            }`}
            onChange={(e) => setActiveExport(e.target.value)}
          >
            <option value=""></option>
            <option value="users=all">تحديد الكل </option>
            <option value="users=housed"> المستخدمين تم تسكينهم </option>
            <option value="users=unhoused"> المستخدمين لم يتم تسكينهم</option>
            <option value="checked">تحديد العنصر</option>
          </select>
          <span className="mr-5 ">(PDF) تصدير كملف</span>
          <img src={ExportPdf} className="w-[20px]" alt="" />
        </div>
        <div
          onClick={() => setExports("excel")}
          className="flex max-sm:flex-col-reverse items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl"
        >
          <select
            className={`mr-5 text-sm  outline-none border-none ${
              exports === "excel" ? "" : "hidden"
            }`}
            onChange={(e) => setActiveExport(e.target.value)}
          >
            <option value=""></option>
            <option value="users=all">تحديد الكل </option>
            <option value="users=housed"> المستخدمين تم تسكينهم </option>
            <option value="users=unhoused"> المستخدمين لم يتم تسكينهم</option>
            <option value="checked">تحديد العنصر</option>
          </select>

          <span className="mr-5 ">(Excel) تصدير كملف</span>
          <img src={ExportPdf} className="w-[20px]" alt="" />
        </div>
        <div></div>
        <div className="flex max-sm:flex-col-reverse items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl">
          <input
            type="text"
            className=" outline-none border-none  max-w-[100px]"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="بحث"
          />
          <select
            className="mr-5 outline-none border-none"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="">الكل</option>
            <option value="&sort_by=name">الاسم</option>
            <option value="&sort_by=username">اسم المستخدم</option>
            <option value="&sort_by=job">الوظيفة</option>
            <option value="&sort_by=age">العمر</option>
            <option value="&sort_by=phone_number">الهاتف</option>
            <option value="&sort_by=party_name">اسم الحزب</option>
            <option value="&sort_by=location">عنوان السكن</option>
            <option value="&sort_by=honesty"> المحافظة</option>
            <option value="&sort_by=membership_number"> رقم العضوية</option>
            <option value="&sort_by=province"> الأمانة</option>
          </select>
          <span>فرز حسب </span>
          <img src={filter} className="w-[20px]" alt="" />
        </div>
      </div>
      <Error error={error} onClick={deleteError} />
      {lo ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Container>
        <div className={`text-center ${activeExport ? "" : "hidden"} my-2 `}>
          <button
            onClick={fetchFile}
            className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            تحميل الملف
          </button>
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => setActive("active=1")}
            className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            حسابات مفعلة
          </button>
          <button
            onClick={() => setActive("active=0")}
            className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            حسابات غير مفعلة
          </button>
        </div>
        <div className="flex  justify-center flex-wrap gap-3">
          {dataAll == 0 ? (
            <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
          ) : (
            ""
          )}
          {awit ? (
            <div>
              <div className="loading"></div>
            </div>
          ) : (
            ""
          )}
          {loading ? (
            <div>
              <div className="loading"></div>
            </div>
          ) : (
            dataAll?.map((e) => (
              <>
                <CardAll
                  check="users"
                  key={e.id}
                  delete={() => deleteItem(e)}
                  update={`/users/account/${e.id}`}
                >
                  <input
                    type="checkbox"
                    value={"users[]=" + e.id}
                    onChange={handleCheckboxChange}
                    className={`${
                      activeExport === "checked" ? "" : "hidden"
                    } absolute -top-3 -left-1 w-5 h-5
              `}
                  />
                  <Link to={`/users/${e.id}`}>
                    <div className="">
                      <img
                        src={e.image ? imgUrl + e.image : imgUser}
                        alt="QR"
                        className="w-[80px] h-[80px] z-20 relative mx-auto -mt-12 rounded-full"
                      />
                    </div>
                    <div className="text-end space-y-2 px-2 pt-5">
                      <BodyCard value={e.username} name=":اسم المستخدم" />
                      <BodyCard value={e.name} name=":الاسم" />
                    </div>
                  </Link>
                  {e.is_active ? (
                    <div className="flex justify-center">
                      <button
                        onClick={() => posttt(e.id)}
                        className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
                      >
                        عدم التفعيل
                      </button>
                    </div>
                  ) : (
                    <Activess>
                      <div className=" flex justify-center flex-col items-center h-full">
                        <input
                          type="text"
                          placeholder="رقم العضوية"
                          onChange={(e) =>
                            setFormData({ membership_number: e.target.value })
                          }
                          className="border border-Brown px-3  py-3 mb-5 rounded-3xl placeholder:text-center text-center"
                        />
                        <div className="text-red-700">{err}</div>
                        <button
                          onClick={() => posttts(e.id)}
                          className=" mx-auto block border border-Brown text-Brown font-semibold bg-white p-3  rounded-xl hover:bg-Brown hover:text-white transition-all"
                        >
                          حفظ
                        </button>
                      </div>
                    </Activess>
                  )}
                </CardAll>
              </>
            ))
          )}
        </div>
        <div className="flex justify-center my-3">
          <ReactPaginate
            className="pagination flex"
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            pageClassName="page-item"
            pageLinkClassName="page-link"
          />
        </div>
        <Add check="users" link={`/users/account/Create-account`} />
      </Container>
    </>
  );
};

export default Users;

const Activess = ({ children }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <button
        onClick={() => setShow(true)}
        className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
      >
        تفعيل
      </button>
      {show && (
        <>
          <div
            onClick={() => setShow(false)}
            className="fixed w-full h-full bg-black bg-opacity-50 top-0 left-0 z-[999]"
          ></div>
          <div className="p-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fixed w-1/3 h-[260px] z-[999999999] rounded-2xl overflow-hidden bg-white">
            {children}
          </div>
        </>
      )}
    </>
  );
};
