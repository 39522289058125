import { useEffect, useState } from "react";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { imgUrl } from "../../../Context/baseUrl";

import Map from "../Map";
import { useContextTitlesHook } from "../../../Context/ContextTitles";
const UpdateCentralSecretariat = () => {
  const [active, setActive] = useState("");
  const { centers } = useContextTitlesHook();
  const { data: provincesda } = useFETCH(`admin/provinces`);
  const {
    handleSubmit,
    setFormData,
    formData,
    img,
    setImg,
    handleChange,
    loading,
    error,
  } = usePOST();
  const { data } = useFETCH(`admin/v2/central-hq-info`);
  let dataUp = data?.data.data.data;
  useEffect(() => {
    setFormData({
      ...formData,
      province_id: dataUp?.province_id,
      title: dataUp?.title,
      first_phone_number: dataUp?.first_phone_number,
      secound_phone_number: dataUp?.secound_phone_number,
      location_link: dataUp?.location_link,
      location_mode: active,
      long: centers[1] || dataUp?.long,
      lat: centers[0] || dataUp?.lat,
    });
  }, [dataUp, centers]);
  useEffect(() => {
    setActive(dataUp?.location_link === null ? "GPS" : "LINK");
  }, [dataUp]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit("admin/v2/central-hq-info");
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6} className="space-y-4 ">
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : صورة
            </h1>
            <AddImage
              clickDelete={() => {
                setImg("");
                setFormData("");
              }}
              name="image"
              onChange={handleChange}
              newimg={img}
              img={imgUrl + data?.data.data.data.image}
            />
            <div className="flex flex-row-reverse -mr-4">
              <pre className="flex items-center border-b py-3 font-semibold text-Brown border-Brown -ml-4 justify-start  max-md:p-0">
                : المحافظة
              </pre>
              <select
                name="province_id"
                value={formData?.province_id}
                onChange={handleChange}
                className="outline-none mr-4 border-b border-Brown w-full"
              >
                <option value="0"></option>
                {provincesda?.data.data.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </div>
            <Imports
              title=" : العنوان"
              name="title"
              value={formData?.title}
              onChange={handleChange}
            />
            <Imports
              title=" : رقم الاول"
              name="first_phone_number"
              value={formData?.first_phone_number}
              onChange={handleChange}
            />
            <Imports
              title=" : رقم الثاني"
              name="secound_phone_number"
              value={formData?.secound_phone_number}
              onChange={handleChange}
            />
            <div className="flex justify-center items-center gap-10">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="location_mode"
                  value="GPS"
                  onChange={handleChange}
                  onClick={(e) => setActive("GPS")}
                />
                <pre>تحديد الموقع على الخريطة </pre>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="location_mode"
                  value="LINK"
                  onChange={handleChange}
                  onClick={(e) => setActive("LINK")}
                />
                <pre> رابط الموقع</pre>
              </div>
            </div>
            {active === "LINK" && (
              <Imports
                title=" : رابط الموقع"
                name="location_link"
                value={formData?.location_link}
                onChange={handleChange}
              />
            )}
            {active === "GPS" && <Map />}
          </Col>
        </Row>

        <div className="text-red-700"> {error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdateCentralSecretariat;
