// واجهة مقرات هيكل الامانة المركزية

import { Add, BodyCard, CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import Title from "../../../components/Title/Title";
import { imgUrl } from "../../../Context/baseUrl";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import ReactPaginate from "react-paginate";
import { useEffect, useRef } from "react";

const CentralSecretariat = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data: dataTilte, isLoading } = useFETCH(
    "admin/presidential-candidates/views-info?info=central_hq"
  );
  const { data: dataInfo } = useFETCH("admin/v2/central-hq-info");
  const { data: dataItems, deleteItem } = useFETCH(
    `admin/v2/central-hq-info/items${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/v2/central-hq-info/items"
  );

  return (
    <Container>
      {isLoading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Row justify="center">
        <Col md={6}>
          <Title
            update="/update-title/central_hq"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col md={6}>
          <CardAll deleteShow={false} update={`/central-secretariat/id`}>
            <div className="w-[100px] h-[100px] m-4 overflow-hidden  mx-auto">
              <img
                src={imgUrl + dataInfo?.data.data.data.image}
                alt=""
                className="w-full h-full bg-black"
              />
            </div>

            <BodyCard
              name=" : المحافظة"
              value={dataInfo?.data.data.data.province}
            />
            <BodyCard
              name=" : العنوان"
              value={dataInfo?.data.data.data.title}
            />
            <BodyCard
              name=" : رقم الهاتف الاول"
              value={dataInfo?.data.data.data.first_phone_number}
            />
            <BodyCard
              name=" : رقم الهاتف الثاني"
              value={dataInfo?.data.data.data.secound_phone_number}
            />
            <br />
            {console.log(dataInfo?.data.data.data.location_link)}
            <div>
              {dataInfo?.data.data.data.location_link ? (
                <a
                  href={dataInfo?.data.data.data.location_link}
                  target="_blank"
                  className="border p-4 border-Brown rounded-2xl text-center block"
                >
                  عرض الخريط
                </a>
              ) : (
                <iframe
                  src={`https://maps.google.com/maps?q=${dataInfo?.data.data.data.lat},${dataInfo?.data.data.data.long}&hl=es;&output=embed`}
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  loading="lazy"
                ></iframe>
              )}
            </div>
          </CardAll>
        </Col>
      </Row>
      <Row justify="center">
        <Col md={4}>
          <Title
            update="/update-title-sub/central-hq"
            iconsShow={false}
            title={dataInfo?.data.data.data.section_name}
          />
        </Col>
      </Row>
      {dataItems?.data.data.data == 0 ? (
            <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
          ) : (
            ""
          )}
      <Row justify="end">
        {dataItems?.data.data.data.map((e) => (
          <Col key={e.id} md={4}>
            <CardAll
              delete={() => deleteItem(e)}
              update={`/central-secretariat/add/${e.id}`}
            >
              <BodyCard name=" : الأسم " value={e.name} />
              <BodyCard name=" : الموقع التنظيمي " value={e.job_title} />
              <BodyCard name=" : رقم الهاتف الهاتف " value={e.phone_number} />
              <BodyCard name=" : الايميل " value={e.email} />
            </CardAll>
          </Col>
        ))}
      </Row>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            dataItems?.data.data.total / dataItems?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link="/central-secretariat/add/add" />
    </Container>
  );
};

export default CentralSecretariat;
