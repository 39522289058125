import { useEffect, useRef, useState } from "react";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  EditorHTML,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import { imgUrl } from "../../../Context/baseUrl";

const UpdateVideos = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { id } = useParams();
  const [video, setVideo] = useState("vid");
  const { data } = useFETCH(
    id !== "add" && `admin/presidential-candidates/videos/${id}`
  );
  const {
    handleSubmit,
    setFormData,
    formData,
    handleChange,
    img,
    loading,
    error,
  } = usePOST();
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        description: content || data?.data.data.description,
        title: formData?.title || data?.data.data.title,
      });
    id === "add" &&
      setFormData({
        ...formData,
        description: content,
      });
  }, [data?.data.data, content]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id !== "add"
        ? `admin/presidential-candidates/videos/${id}`
        : "admin/presidential-candidates/videos"
    );
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6} className="space-y-4">
            <Imports
              value={formData?.title}
              onChange={handleChange}
              name="title"
              title=" : العنوان"
            />
            <div className="flex justify-center items-center gap-10">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="video"
                  value="vid"
                  onChange={(e) => setVideo(e.target.value)}
                />
                <pre>فيديو </pre>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="video"
                  value="urlVid"
                  onChange={(e) => setVideo(e.target.value)}
                />
                <pre>رابط فيديو</pre>
              </div>
            </div>
            {video === "vid" ? (
              <AddImage name="video" onChange={handleChange} title="الفيديو" />
            ) : (
              <Imports
                name="video"
                onChange={handleChange}
                title=" : الرابط الفيديو"
              />
            )}
            {img && (
              <video controls>
                <source src={img} />
              </video>
            )}
            {data?.data.data &&
              (data?.data.data.video.substring(0, 4) === "http" ? (
                <div className=" h-[230px]">
                  <iframe
                    className="video"
                    title="Youtube player"
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={`https://youtube.com/embed/${
                      imgUrl + data?.data.data.video.substring(32, 43)
                    }?autoplay=0`}
                  ></iframe>
                </div>
              ) : (
                <div className="flex items-center h-[230px]">
                  <video
                    className="rounded-2xl object-cover container mx-auto w-full h-full "
                    controls
                  >
                    <source src={imgUrl + data?.data.data.video} />
                  </video>
                </div>
              ))}
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <div>
              <JoditEditor
                ref={editor}
                value={data?.data.data.description}
                onChange={(newContent) => setContent(newContent)}
              />
            </div>
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>
          {id !== "add" ? "تعديل" : "أضافة"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdateVideos;
