import { Add, CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import Title from "../../../components/Title/Title";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import parse from "html-react-parser";
import ReactPaginate from "react-paginate";
import { useEffect, useRef } from "react";
const PartyList = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();

  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=party_list"
  );
  const { data: dataTilte2 } = useFETCH("admin/v2/party-list/section-name");
  const {
    data: dataItems,
    deleteItem,
    isLoading,
  } = useFETCH(
    `admin/v2/party-list/items${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/v2/party-list/items"
  );
  return (
    <Container>
      <Row justify="center">
        <Col md={5}>
          <Title
            update="/update-title/party_list"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col md={5}>
          <Title
            update="/update-title-sub/party_list"
            iconsShow={false}
            title={dataTilte2?.data.data.party_list_section_name}
          />
        </Col>
      </Row>
      <Row justify="end">
        {isLoading ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          dataItems?.data.data.data.map((e) => (
            <Col key={e.id} md={6} lg={4}>
              <CardAll
                delete={() => deleteItem(e)}
                update={`/party-list/${e.id}`}
              >
                <h1 className="text-Brown text-xl font-semibold text-center">
                  {e.title1}
                </h1>
                <h1 className="text-Brown text-xl font-semibold text-center">
                  {e.title2}
                </h1>
                <p>{parse(e.text)}</p>
              </CardAll>
            </Col>
          ))
        )}
      </Row>

      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            dataItems?.data.data.total / dataItems?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link="/party-list/add" />
    </Container>
  );
};

export default PartyList;
