import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {  HashRouter } from "react-router-dom";
import ContextProvider from "./Context/ContextOPen";
import ContextTitlesProvider from "./Context/ContextTitles";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ContextTitlesProvider>
        <ContextProvider>
          <App />
        </ContextProvider>
      </ContextTitlesProvider>
    </HashRouter>
  </React.StrictMode>
);
