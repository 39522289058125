import { Link } from "react-router-dom";
import useFetch from "../../Context/useFetch";
import { Border, Container } from "../../components/index";
import Img2 from "../../images/edit (2).png";
import { imgUrl } from "../../Context/baseUrl";
const PublicInformation = () => {
  const { data, loading } = useFetch(`admin/site-info/general`);
  const { data: data2 } = useFetch(`admin/site-info/social`);
  const { data: data3 } = useFetch(`admin/site-info/medical-relief-info`);
  const { data: data4 } = useFetch(`admin/site-info/v2/app-sound`);

  let x = JSON.parse(localStorage.getItem("site_info")) || "";
  return (
    <Container>
      <Border>
        <Link
          className={`${x[1] === "cpanel_read_only_admin" ? "hidden" : ""}`}
          to={`/publicInformation/Updite-Public-Information`}
        >
          <img src={Img2} alt="" className="cursor-pointer" />
        </Link>
        {loading ? (
          <div>
            <div className="loading"></div>
          </div>
        ) : (
          <div className="space-y-3">
            <h1 className="text-center font-semibold text-Brown ">
              معلومات عامة
            </h1>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : بداية العمل</div>
              <div>{data?.data.data.start_time}</div>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : نهاية العمل</div>
              <div>{data?.data.data.end_time}</div>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2 items-center">
              <div className="text-Brown"> : نغمة التطبيق </div>
              {data4?.data.data.app_sound ? (
                <div>
                  <audio controls>
                    <source src={imgUrl + data4?.data.data.app_sound} />
                  </audio>
                </div>
              ) : (
                <div>
                  <div className="loading"></div>
                </div>
              )}

              {data4?.data.data.app_sound_status === "1" ? (
                <div className="text-green-700">مفعل</div>
              ) : data4?.data.data.app_sound_status === "0" ? (
                <div className="text-red-700"> غير مفعل </div>
              ) : (
                ""
              )}
            </div>
            <h1 className="text-center font-semibold text-Brown">للتواصل</h1>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : twitter</div>
              <a target="_blank" href={data2?.data.data.twitter}>
                <div>{data2?.data.data.twitter.substring(0, 70)}... </div>
              </a>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2 ">
              <div className="text-Brown"> : facebook </div>
              <a target="_blank" href={data2?.data.data.facebook}>
                <div>{data2?.data.data.facebook.substring(0, 70)}... </div>
              </a>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : whatsapp </div>
              <a target="_blank" href={data2?.data.data.whatsapp}>
                <div>{data2?.data.data.whatsapp.substring(0, 70)}... </div>
              </a>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : linkedin </div>
              <a target="_blank" href={data2?.data.data.linkedin}>
                <div>{data2?.data.data.linkedin.substring(0, 70)}... </div>
              </a>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : instagram </div>
              <a target="_blank" href={data2?.data.data.instagram}>
                <div>{data2?.data.data.instagram.substring(0, 70)}... </div>
              </a>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : website</div>
              <a target="_blank" href={data2?.data.data.website_url}>
                <div>{data2?.data.data.website_url.substring(0, 70)}... </div>
              </a>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : youtube</div>
              <a target="_blank" href={data2?.data.data.youtube_url}>
                <div>{data2?.data.data.youtube_url.substring(0, 70)}... </div>
              </a>
            </div>
            <h1 className="text-center font-semibold text-Brown ">
              معلومات طلبا ت الاستغاثة
            </h1>
            <div className="flex flex-row-reverse font-semibold gap-2 ">
              <div className="text-Brown flex items-start"> : النص</div>
              <div className=" max-sm:w-full text-end">
                {data3?.data.data.text}
              </div>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : رقم الهاتف الأول</div>
              <div className="text-center">{data3?.data.data.first_phone}</div>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2">
              <div className="text-Brown"> : رقم الهاتف الثاني</div>
              <div>{data3?.data.data.third_phone}</div>
            </div>
            <div className="flex flex-row-reverse font-semibold gap-2 items-center">
              <div className="text-Brown"> : رقم الهاتف الثالث</div>
              <div>{data3?.data.data.secound_phone}</div>
            </div>
          </div>
        )}
      </Border>
    </Container>
  );
};

export default PublicInformation;
