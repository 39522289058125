import { useEffect } from "react";
import { Border, ButtonApp, Container, Imports } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const UpdateCV = () => {
  const { data } = useFETCH(`presidential-candidates/profile/biography`);
  const {
    handleSubmit,
    setFormData,
    formData,
    handleChange,
    img,
    loading,
    error,
  } = usePOST();
  let dataUp = data?.data.data;
  useEffect(() => {
    setFormData({
      name: dataUp?.name,
      Religion: dataUp?.Religion,
      position: dataUp?.position,
      Occupation: dataUp?.Occupation,
      party: dataUp?.party,
      current: dataUp?.current,
      start_date: dataUp?.start_date,
      end_date: dataUp?.end_date,
    });
  }, [dataUp]);

  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit("admin/presidential-candidates/profile/biography");
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row className="p-4 flex-row-reverse">
          <Col lg={6}>
            <Imports
              onChange={handleChange}
              name="name"
              value={formData?.name}
              title=" : الأسم"
            />
          </Col>
          <Col lg={6}>
            <Imports
              onChange={handleChange}
              name="Religion"
              value={formData?.Religion}
              title=" : الديانة"
            />
          </Col>
          <Col lg={6}>
            <Imports
              onChange={handleChange}
              name="position"
              value={formData?.position}
              title=" : المنصب"
            />
          </Col>
          <Col lg={6}>
            <Imports
              onChange={handleChange}
              name="Occupation"
              value={formData?.Occupation}
              title=" : المهنة"
            />
          </Col>
          <Col lg={6}>
            <Imports
              onChange={handleChange}
              name="party"
              value={formData?.party}
              title=" : الحزب"
            />
          </Col>
          <Col lg={6}>
            <Imports
              onChange={handleChange}
              name="current"
              value={formData?.current}
              title=" : التيار"
            />
          </Col>
          <Col lg={6}>
            <Imports
              onChange={handleChange}
              name="start_date"
              value={formData?.start_date}
              title=" : تاريخ البداية"
            />
          </Col>
          <Col lg={6}>
            <Imports
              onChange={handleChange}
              name="end_date"
              value={formData?.end_date}
              title=" : تاريخ النهاية"
            />
          </Col>
        </Row>
        <div>{error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdateCV;
