import { Add, BodyCard, CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { Link, useParams } from "react-router-dom";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import ReactPaginate from "react-paginate";
import { useEffect, useRef } from "react";

const ProvincialSecretariat3 = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { id, idu } = useParams();
  const { data } = useFETCH(`admin/v2/provincial-hqs/subs/${id}`);
  const {
    data: dataItems,
    isLoading,
    deleteItem,
  } = useFETCH(
    `admin/v2/provincial-hqs/subs/${id}/subs${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/v2/provincial-hqs/subs/subs"
  );

  return (
    <Container>
      {isLoading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Row justify="center">
        <Col md={7}>
          <div className="border border-Brown rounded-xl p-4 text-center">
            {data?.data.data.name}
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col md={4}>
          <CardAll
            update={`/provincial-secretariat/${id}/page3/u/${idu}`}
            deleteShow={false}
          >
            <div className="w-[100px] h-[100px] m-4 overflow-hidden  mx-auto">
              <img
                src={imgUrl + data?.data.data.image}
                alt=""
                className="w-full h-full bg-black"
              />
            </div>

            <BodyCard name=" : العنوان" value={data?.data.data.location} />
            <BodyCard name=" : المحافظة" value={data?.data.data.province} />
            <br />
            <div>
              {data?.data.data.location_link ? (
                <a
                  href={data?.data.data.location_link}
                  target="_blank"
                  className="border p-4 border-Brown rounded-2xl text-center block"
                >
                  عرض الخريط
                </a>
              ) : (
                <iframe
                  src={`https://maps.google.com/maps?q=${data?.data.data.lat},${data?.data.data.long}&hl=es;&output=embed`}
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  loading="lazy"
                ></iframe>
              )}
            </div>
          </CardAll>
        </Col>
      </Row>
      {dataItems?.data.data.data.data == 0 ? (
        <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
      ) : (
        ""
      )}
      <Row justify="end">
        {dataItems?.data.data.data.data.map((e) => (
          <Col key={e.id} xs={6} md={4} lg={3}>
            <CardAll
              delete={() => deleteItem(e)}
              update={`/provincial-secretariat/${id}/page3/${e.id}`}
            >
              <Link to={`/provincial-secretariat/${e.id}/page4`}>{e.name}</Link>
            </CardAll>
          </Col>
        ))}
      </Row>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            dataItems?.data.data.data.total / dataItems?.data.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <Add link={`/provincial-secretariat/${id}/page3/add`} />
    </Container>
  );
};

export default ProvincialSecretariat3;
