import { useParams } from "react-router-dom";
import { baseUrl, imgUrl } from "../../Context/baseUrl";
import { Border, Button, ButtonApp, Imports } from "../../components";
import { Container } from "../../components/index";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../Context/useFetch";

const MeetingTwo = () => {
  const [videoUrl, setVideoUrl] = useState();
  const [video, setVideo] = useState();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [max, setMax] = useState("");
  const [postData, setPostData] = useState({
    name: "",
    description: "",
  });
  const { data } = useFetch(`admin/forum-videos/${id}`);
  let dataAll = data?.data.data;
  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const videoUrl = URL.createObjectURL(file);
    setVideoUrl(videoUrl);
    setVideo(file);
  };
  const postRe = (e) => {
    setLoading(true);
    setError("");
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("name", postData.name);
    formdata.append("description", postData.description);
    formdata.append(video ? "video" : "", video);
    axios
      .post(
        `${baseUrl}${
          isNaN(id) ? "admin/forum-videos" : `admin/forum-videos/${id}`
        }`,
        formdata,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((auth) => {
        if (auth) {
          setLoading(false);
          window.history.go(-1);
          setError("");
        }
      })
      .catch((e) => {
        setError(Object.values(e?.response.data.errors));
        setLoading(false);
      });
  };
  useEffect(() => {
    setPostData({
      name: dataAll?.name,
      description: dataAll?.description,
    });
    setVideo(dataAll?.video.substring(0, 4) === "http" ? dataAll?.video : "");
    setMax(
      dataAll?.video && dataAll?.video.substring(0, 4) !== "http"
        ? "video"
        : "url"
    );
  }, [dataAll]);
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <div className="space-y-10 ">
          <div className="flex gap-4 max-md:flex-wrap flex-row-reverse lg:gap-15">
            <Imports
              value={postData.name}
              title=" : اسم الفيديو"
              onChange={(e) =>
                setPostData({ ...postData, name: e.target.value })
              }
            />
            <Imports
              value={postData.description}
              title=" : شرح الفيديو"
              onChange={(e) =>
                setPostData({ ...postData, description: e.target.value })
              }
            />
          </div>
          <div className="flex justify-end space-x-3 items-center">
            <div className="flex justify-end gap-1 items-center">
              <span> رفع رابط فيديو </span>
              <input
                value="url"
                checked={
                  dataAll?.video.substring(0, 4) === "http" ? true : null
                }
                type="radio"
                name="max"
                onChange={(e) => setMax(e.target.value)}
              />
            </div>
            <div className="flex justify-end gap-1 items-center">
              <span> رفع فيديو </span>
              <input
                value="video"
                checked={
                  dataAll?.video && dataAll?.video.substring(0, 4) !== "http"
                    ? true
                    : null
                }
                type="radio"
                name="max"
                onChange={(e) => setMax(e.target.value)}
              />
            </div>
          </div>
          {max === "video" ? (
            <div className=" min-h-[150px] border-2 font-semibold border-dashed border-slate-400 text-Brown w-[75%] mx-auto rounded-lg ">
              <div
                className={`${
                  videoUrl
                    ? "hidden "
                    : "flex items-center justify-center h-[30vh]"
                }`}
              >
                <input
                  id="file-input"
                  type="file"
                  onChange={handleVideoChange}
                  accept="video/mp4,video/x-m4v,video/*"
                  style={{ display: "none" }}
                />
                <button
                  onClick={() => document.getElementById("file-input").click()}
                  className="block w-full h-full"
                >
                  <div className="flex items-center justify-center mx-auto text-lg hover:text-orange-800">
                    أضف فيديو
                  </div>
                </button>
              </div>

              <div>
                {videoUrl && (
                  <div className="relative">
                    <div
                      onClick={() => {
                        setVideo();
                        setVideoUrl();
                      }}
                      className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                    >
                      X
                    </div>
                    <video controls className="w-full h-[400px] rounded-lg">
                      <source src={videoUrl} type="video/mp4" />
                    </video>
                  </div>
                )}
                {dataAll?.video && (
                  <div className="w-full h-[400px] rounded-lg">
                    <video
                      className="rounded-2xl container mx-auto w-full h-full "
                      controls
                    >
                      <source src={`${imgUrl}${dataAll?.video}`} />
                    </video>
                  </div>
                )}
              </div>
            </div>
          ) : max === "url" ? (
            <div className="w-3/4 mx-auto">
              <Imports
                value={video}
                title=" : رابط الفيديو"
                onChange={(e) => setVideo(e.target.value)}
              />
            </div>
          ) : (
            ""
          )}
          <div className="text-red-500">
            {error && error.map((e) => <div>{e}</div>)}
          </div>
          {isNaN(id) ? (
            <Button click={postRe} />
          ) : (
            <ButtonApp onClick={postRe}>
              <span className="px-5">تعديل</span>
            </ButtonApp>
          )}
        </div>
      </Border>
    </Container>
  );
};

export default MeetingTwo;
