import { useEffect, useState } from "react";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  Imports,
} from "../../../components";
import { useContextTitlesHook } from "../../../Context/ContextTitles";
import { Col, Row } from "../../../Grid-system";
import Map from "../Map";
import { imgUrl } from "../../../Context/baseUrl";
import { useParams } from "react-router-dom";

const UpdateProvincialSecretariat3 = () => {
  const { id } = useParams();
  const { centers } = useContextTitlesHook();
  const { data: provincesda } = useFETCH(`admin/provinces`);
  const {
    handleSubmit,
    setFormData,
    formData,
    img,
    setImg,
    handleChange,
    loading,
    error,
  } = usePOST();
  const { data } = useFETCH(`admin/v2/provincial-hqs/subs/${id}`);

  let dataUp = data?.data.data;
  useEffect(() => {
    setFormData({
      ...formData,
      long: centers[1] || dataUp?.long,
      lat: centers[0] || dataUp?.lat,
      location_link: formData?.location_link || dataUp?.location_link,
      province_id: formData?.province_id || dataUp?.province_id,
      location: formData?.location || dataUp?.location,
      location_mode: active,
    });
  }, [dataUp, centers]);
  useEffect(() => {
    setActive(
      id !== "add" &&
        (dataUp?.location_link === null || dataUp?.location_link === undefined
          ? "GPS"
          : "LINK")
    );
  }, [dataUp]);
  const [active, setActive] = useState("");

  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(`admin/v2/provincial-hqs/subs/${id}/update/all`);
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6} className="space-y-4">
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : الصورة
            </h1>
            <AddImage
              clickDelete={() => {
                setImg("");
                setFormData("");
              }}
              name="image"
              onChange={handleChange}
              newimg={img}
              img={data?.data.data?.image && imgUrl + data?.data.data?.image}
            />
            <Imports
              title=" : العنوان"
              name="location"
              value={formData?.location}
              onChange={handleChange}
            />
            <div className="flex flex-row-reverse -mr-4">
              <pre className="flex items-center border-b py-3 font-semibold text-Brown border-Brown -ml-4 justify-start  max-md:p-0">
                : المحافظة
              </pre>
              <select
                name="province_id"
                value={formData?.province_id}
                onChange={handleChange}
                className="outline-none mr-4 border-b border-Brown w-full"
              >
                <option value="0"></option>
                {provincesda?.data.data.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-center items-center gap-10">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="location_mode"
                  value={"GPS"}
                  onChange={handleChange}
                  onClick={(e) => setActive("GPS")}
                />
                <pre>تحديد الموقع على الخريطة </pre>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="location_mode"
                  value={"LINK"}
                  onChange={handleChange}
                  onClick={(e) => setActive("LINK")}
                />
                <pre> رابط الموقع</pre>
              </div>
            </div>
            {active === "LINK" && (
              <Imports
                title=" : رابط الموقع"
                name="location_link"
                value={formData?.location_link}
                onChange={handleChange}
              />
            )}
            {active === "GPS" && <Map />}
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdateProvincialSecretariat3;
