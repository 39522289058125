import Logo from "../../images/logo.png";
import { useState } from "react";
import { useLogin } from "../../Context/usePost";
import { BiHide, BiShow } from "react-icons/bi";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [biShow, setBiShow] = useState(false);
  const { postLogin, error, loading } = useLogin("admin/login", {
    email: email,
    password: password,
  });

  return (
    <div className="fixed w-full h-full bg-white top-0 left-0 z-[9999] flex items-center justify-center text-center">
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Brown p-10 rounded-2xl">
        <div className="flex justify-center">
          <img src={Logo} alt="" className="w-[120px] " />
        </div>
        <div className="flex  max-sm:flex-col-reverse w-full mx-3">
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none text-end placeholder:text-Brown"
          />
          <pre className="flex items-center max-sm:border-none max-sm:text-end max-sm:block border-b font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
            :بريد الالكتروني
          </pre>
        </div>
        <div className="flex  max-sm:flex-col-reverse w-full mx-3 relative">
          <input
            type={biShow ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none text-end placeholder:text-Brown"
          />
          <pre className="flex items-center max-sm:border-none max-sm:text-end max-sm:block border-b font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
            :كلمة السر
          </pre>
          {biShow ? (
            <BiShow
              onClick={() => setBiShow(false)}
              size={20}
              className="absolute left-0 top-5 cursor-pointer"
            />
          ) : (
            <BiHide
              onClick={() => setBiShow(true)}
              size={20}
              className="absolute left-0 top-5 cursor-pointer"
            />
          )}
        </div>
        <div className="text-red-500">
          {error && error.map((e) => <div>{e}</div>)}
        </div>
        <div onClick={postLogin} className=" ">
          <button
            onClick={postLogin}
            className="m-3 border border-Brown w-1/2 max-sm:w-full text-Brown font-semibold bg-white py-2 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            <span className="px-12">ارسال</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
