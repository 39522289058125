import { useEffect, useRef, useState } from "react";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  EditorHTML,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import JoditEditor from "jodit-react";
import { imgUrl } from "../../../Context/baseUrl";

const AddPartyNews = () => {
  const [video, setVideo] = useState("");
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const { id } = useParams();
  const { data } = useFETCH(id !== "add" && `admin/v2/party-news/${id}`);
  const {
    handleSubmit,
    setFormData,
    formData,
    handleChange,
    img,
    loading,
    setImgs,
    error,
    handleChangeArray,
    imgs,
    imgss,
    setImgss,
    deleteCheckArray,
  } = usePOST();
  useEffect(() => {
    id !== "add" &&
      setFormData({
        ...formData,
        text: content || data?.data.data.text,
        title: formData?.title || data?.data.data.title,
        media_type: formData?.media_type || data?.data.data.media_type,
      });
    id === "add" &&
      setFormData({
        ...formData,
        text: content,
      });
  }, [data?.data.data, content]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id !== "add" ? `admin/v2/party-news/${id}` : "admin/v2/party-news"
    );
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={7} className="space-y-4">
            <Imports
              value={formData?.title}
              onChange={handleChange}
              name="title"
              title=" : العنوان"
            />
            <div className="flex justify-center items-center gap-10">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="media_type"
                  value="VIDEO"
                  onClick={(e) => setVideo(e.target.value)}
                  onChange={handleChange}
                />
                <pre>فيديو </pre>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="media_type"
                  value="VIDEO"
                  onClick={(e) => setVideo("url")}
                  onChange={handleChange}
                />
                <pre>رابط فيديو</pre>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="media_type"
                  value="IMAGES"
                  onClick={(e) => setVideo(e.target.value)}
                  onChange={handleChange}
                />
                <pre>صور</pre>
              </div>
            </div>
            {video === "VIDEO" ? (
              <AddImage name="video" onChange={handleChange} title="الفيديو" />
            ) : video === "IMAGES" ? (
              <>
                <AddImage
                  id="main_images"
                  name="main_images[]"
                  onChange={handleChangeArray}
                  title="صور"
                />
                <Row>
                  {imgs &&
                    imgs.map((e, i) => (
                      <Col key={i} md={4}>
                        <div className="relative">
                          <span
                            onClick={() => {
                              let total = [...imgs];
                              let total2 = [...imgss];
                              total.splice(i, 1);
                              total2.splice(i, 1);
                              setImgs(total);
                              setImgss(total2);
                            }}
                            className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer -top-2 rounded-full absolute"
                          >
                            X
                          </span>
                          <img src={e} alt="" className="w-full  rounded-2xl" />
                        </div>
                      </Col>
                    ))}
                </Row>
              </>
            ) : video === "url" ? (
              <Imports
                name="video"
                onChange={handleChange}
                title=" : الرابط الفيديو"
              />
            ) : (
              ""
            )}
            {img && (
              <video controls>
                <source src={img} />
              </video>
            )}
            {data?.data.data.media_type === "VIDEO" &&
              (data?.data.data.video.substring(0, 4) === "http" ? (
                <div className=" h-[230px]">
                  <iframe
                    className="video"
                    title="Youtube player"
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={`https://youtube.com/embed/${
                      imgUrl + data?.data.data.video.substring(32, 43)
                    }?autoplay=0`}
                  ></iframe>
                </div>
              ) : (
                <div className="flex items-center h-[230px]">
                  <video
                    className="rounded-2xl object-cover container mx-auto w-full h-full "
                    controls
                  >
                    <source src={imgUrl + data?.data.data.video} />
                  </video>
                </div>
              ))}
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <JoditEditor
              ref={editor}
              value={data?.data.data.text}
              onChange={(newContent) => setContent(newContent)}
            />
          </Col>
        </Row>
        {data?.data.data.images && (
          <div
            onClick={() => setShowDelete(!showDelete)}
            className="m-3 border border-Brown w-fit text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
          >
            حذف الصور
          </div>
        )}
        <Row justify="center">
          {data?.data.data.images &&
            data?.data.data.images.map((e) => (
              <Col md={4} lg={3} className="relative">
                {showDelete && (
                  <input
                    type="checkbox"
                    value={e.id}
                    name="main_images_to_delete_ids[]"
                    onChange={deleteCheckArray}
                    className="absolute w-5 h-5 -top-2 -left-1"
                  />
                )}
                <img
                  src={imgUrl + e.image}
                  alt=""
                  className="h-[250px] w-full"
                />
              </Col>
            ))}
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>
          {id !== "add" ? "تعديل" : "أضافة"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default AddPartyNews;
