import { CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import Title from "../../../components/Title/Title";
import { Link } from "react-router-dom";
import { useFETCH, useFilter } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";
import { useEffect, useRef } from "react";

const PresidentialProgramme = () => {
  const { filter, handlePageClick } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data, isLoading } = useFETCH(
    "admin/presidential-candidates/programme/info"
  );
  const {
    data: dataItems,

    deleteItem,
  } = useFETCH(
    `admin/presidential-candidates/programme/items${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/presidential-candidates/programme/items"
  );
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=presidential_candidate_programme"
  );

  return (
    <Container>
      {isLoading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Row justify="center">
        <Col md={5}>
          <Title
            update="/update-title/presidential_candidate_programme"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col md={7}>
          <CardAll update="/update-presidential-programme" deleteShow={false}>
            <div className="flex justify-between">
              <a href={imgUrl + data?.data.data.presidential_programme_pdf}>
                <div className="border border-Brown rounded-2xl p-2">
                  pdf عرض ملف
                </div>
              </a>
              <div> {data?.data.data.presidential_programme_text}</div>
            </div>
          </CardAll>
        </Col>
      </Row>
      {dataItems?.data.data.data == 0 ? (
        <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
      ) : (
        ""
      )}
      <Row justify="end">
        {dataItems?.data.data.data.map((e) => (
          <Col key={e.id} md={6} lg={4}>
            <CardAll
              delete={() => deleteItem(e)}
              update={`/update-presidential-programme2/${e.id}`}
            >
              <div className="text-center text-xl text-Brown font-semibold">
                {e.name}
              </div>
              <div className="text-center text-xl text-Brown font-semibold">
                {e.title}
              </div>
              <p className="text-center">{parse(e.text)}</p>
            </CardAll>
          </Col>
        ))}
      </Row>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            dataItems?.data.data.total / dataItems?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <div className="mt-5">
        <Link
          to="/update-presidential-programme2/add"
          className="m-3 border  border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
        >
          أضافة
        </Link>
      </div>
    </Container>
  );
};

export default PresidentialProgramme;
