import { Container, DeleteIcon, Imports } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import Title from "../../../components/Title/Title";
import { useFETCH } from "../../../APIs/useMyAPI";
import ReactPaginate from "react-paginate";
import { baseUrl, imgUrl } from "../../../Context/baseUrl";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import ExportPdf from "../../../images/Export.png";
const AudienceRequests = () => {
  const [activeExport, setActiveExport] = useState("");
  const [awit, setAwit] = useState(false);
  const [exports, setExports] = useState("");
  const [arrey, setarrey] = useState();
  const [_id, set_id] = useState();
  const { filter, setFilter, handlePageClick } = useFilter();
  const { data: provincesda } = useFETCH(`admin/provinces`);
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = filter.get("page")
        ? filter.get("page") - 1
        : 0;
    }
  }, [filter.get("page")]);
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/audience-requests${
      filter.get("sort_by") || filter.get("search") || filter.get("page")
        ? "?"
        : ""
    }${filter.get("sort_by") ? "&sort_by=" + filter.get("sort_by") : ""}${
      filter.get("search") ? "&search=" + filter.get("search") : ""
    }${filter.get("page") ? "&page=" + filter.get("page") : ""}`,
    "admin/audience-requests"
  );
  const { data: dataTilte } = useFETCH(
    "admin/presidential-candidates/views-info?info=audience_requests"
  );
  let jjj = arrey;
  useEffect(() => {}, [activeExport]);
  const selectedCheckboxesRef = useRef([]);
  function handleCheckboxChange(event) {
    const { value, checked } = event.target;
    if (checked) {
      selectedCheckboxesRef.current.push(value);
      setarrey(selectedCheckboxesRef.current);
    } else {
      selectedCheckboxesRef.current = selectedCheckboxesRef.current.filter(
        (item) => item !== value
      );
      setarrey(selectedCheckboxesRef.current);
    }
  }
  const fetchFile = () => {
    setAwit(true);
    axios({
      url: `${baseUrl}${`admin/audience-requests/export/${exports}?${
        activeExport === "checked"
          ? jjj?.join("&")
          : activeExport === "province_id"
          ? `${activeExport}=${_id}`
          : activeExport
      }`}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setAwit(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = `file.${exports === "pdf" ? "pdf" : "xlsx"}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
        setAwit(false);
      });
  };
  return (
    <Container>
      <Row justify="center" className="pb-4">
        <Col md={5}>
          <Title
            update="/update-title/audience_requests"
            title={dataTilte?.data.data.title}
            icon={imgUrl + dataTilte?.data.data.icon}
          />
        </Col>
      </Row>
      <Row justify="" className="items-center gap-4">
        <Col md={7} className="flex justify-center gap-2">
          <div
            onClick={() => setExports("pdf")}
            className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown  cursor-pointer border border-Brown rounded-xl"
          >
            <select
              className={`mr-5 outline-none border-none ${
                exports === "pdf" ? "" : "hidden"
              }`}
              onChange={(e) => setActiveExport(e.target.value)}
            >
              <option value=""></option>
              <option value="a_requests=all">تحديد الكل </option>
              <option value="checked">تحديد العنصر</option>
              <option value="province_id">تحديد حسب محافظة</option>
            </select>
            <span className="mr-5 ">(PDF) تصدير كملف</span>
            <img src={ExportPdf} className="w-[20px]" alt="" />
          </div>
          <div
            onClick={() => setExports("excel")}
            className="flex max-sm:flex-col-reverse  items-center justify-end py-3 px-6 font-semibold text-Brown cursor-pointer border border-Brown rounded-xl"
          >
            <select
              className={`mr-5 outline-none border-none ${
                exports === "excel" ? "" : "hidden"
              }`}
              onChange={(e) => setActiveExport(e.target.value)}
            >
              <option value=""></option>
              <option value="a_requests=all">تحديد الكل </option>
              <option value="checked">تحديد العنصر</option>
              <option value="province_id">تحديد حسب محافظة</option>
            </select>

            <span className="mr-5 ">(Excel) تصدير كملف</span>
            <img src={ExportPdf} className="w-[20px]" alt="" />
          </div>
        </Col>
        <Col md={3}>
          <Imports
            placeholder="بحث"
            value={filter.get("search")}
            onChange={(e) =>
              setFilter({
                search: e.target.value,
                sort_by: filter.get("sort_by") ? filter.get("sort_by") : "",
                page: filter.get("page") ? filter.get("page") : "",
              })
            }
          />
        </Col>
        <Col md={1}>
          <select
            value={filter.get("sort_by")}
            defaultValue=""
            onChange={(e) =>
              setFilter({
                search: filter.get("search") ? filter.get("search") : "",
                sort_by: e.target.value,
                page: filter.get("page") ? filter.get("page") : "",
              })
            }
            className="outline-none"
          >
            <option value="">الكل</option>
            <option value="full_name">الاسم </option>
            <option value="age">العمر</option>
            <option value="phone_number">رقم الهاتف</option>
            <option value="city_or_center">المركز أو المدينة </option>
            <option value="village">القرية</option>
            <option value="province">المحافظة</option>
          </select>
        </Col>
      </Row>
      {activeExport === "province_id" && (
        <div className="flex flex-row-reverse  w-fit mx-auto">
          <pre className="flex items-center border-b py-3 font-semibold text-Brown border-Brown -ml-4 justify-start  max-md:p-0">
            : المحافظة
          </pre>
          <select
            name="province_id"
            onChange={(e) => set_id(e.target.value)}
            className="outline-none mr-4 border-b border-Brown w-full"
          >
            <option value="0"></option>
            {provincesda?.data.data.map((e) => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className={`text-center ${activeExport ? "" : `hidden`} my-2 `}>
        <button
          onClick={fetchFile}
          className="m-3 border border-Brown text-Brown font-semibold bg-white px-12 py-1 rounded-xl hover:bg-Brown hover:text-white transition-all"
        >
          تحميل الملف
        </button>
      </div>
      <br />
      <Row justify="center">
        <table className=" text-2xl text-center w-full mx-auto ">
          <thead className="text-Brown">
            <tr>
              <td>احداث</td>
              <td>العمر </td>
              <td>المركز أو المدينة</td>
              <td>رقم الهاتف </td>
              <td>المحافظة </td>
              <td>القرية </td>
              <td>الاسم الكامل </td>
            </tr>
          </thead>

          <tbody className="">
            {isLoading || awit ? (
              <div>
                <div className="loading"></div>
              </div>
            ) : (
              data?.data.data.data?.map((e) => (
                <tr>
                  <td>
                    <div className="flex justify-center gap-6 ">
                      <DeleteIcon onClick={() => deleteItem(e)} />
                    </div>
                    <input
                      type="checkbox"
                      value={"a_requests[]=" + e.id}
                      onChange={handleCheckboxChange}
                      className={`${
                        activeExport === "checked" ? "" : "hidden"
                      } absolute top-1 -left-0 w-5 h-5
              `}
                    />
                  </td>

                  <td>{e.age} </td>
                  <td>{e.city_or_center} </td>
                  <td>{e.phone_number}</td>
                  <td>{e.province}</td>
                  <td>{e.village}</td>
                  <td>{e.full_name}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {data?.data.data.data == 0 ? (
          <h1 className="text-center text-3xl">لا يوجد بيانات</h1>
        ) : (
          ""
        )}
      </Row>
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
    </Container>
  );
};

export default AudienceRequests;
const useFilter = () => {
  const [filter, setFilter] = useSearchParams();
  const handlePageClick = (event) => {
    setFilter({
      page: event.selected + 1,
      search: filter.get("search") ? filter.get("search") : "",
      sort_by: filter.get("sort_by") ? filter.get("sort_by") : "",
    });
  };
  return { filter, handlePageClick, setFilter };
};
