import { Navigate, Outlet } from "react-router-dom";
import useFetch from "./useFetch";
// عدم دخول من غير تسجيل دخول
const RequireAuth = ({ children }) => {
  const { data } = useFetch("admin/auth/check");
  if (!window.localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};
// عدم فتح صفحة تسجيل دخول اذا مسجل مسيقا
export const RequireLogin = ({ children }) => {
  if (window.localStorage.getItem("token")) {
    return <Navigate to="/" />;
  }
  return children;
};
// شرط تحقق اذا يسمح للادمن دخول الى هذا الصفحة
export const RequireUsers = (props) => {
  let x = JSON.parse(localStorage.getItem(props.name)) || [];
  if (x[0] === false) {
    return <Navigate to="/403" />;
  }
  return <Outlet />;
};

export default RequireAuth;
