const BodyCard = (props) => {
  return (
    <div className="flex justify-end ml-auto text-end items-start    max-sm:w-[200px] ">
      {props.link ? (
        <a href={props.link} target="_blank">
          <div className="break">
            {props.value === "-1" ||
            props.value === "null" ||
            props.value === "undefined" ||
            props.value === "text nullable optional"
              ? "لا يوجد بيانات"
              : props.value
              ? props.value
              : "لا يوجد بيانات"}
          </div>
        </a>
      ) : (
        <div className="">
          {props.value === "-1" ||
          props.value === "null" ||
          props.value === "undefined" ||
          props.value === "text nullable optional"
            ? "لا يوجد بيانات"
            : props.value
            ? props.value
            : "لا يوجد بيانات"}
        </div>
      )}
      <div className="text-Brown font-semibold  ">
        <pre className="w-fit">{props.name}</pre>
      </div>
    </div>
  );
};

export default BodyCard;
