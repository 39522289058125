import { useEffect } from "react";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { Border, ButtonApp, Container, Imports } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useParams } from "react-router-dom";

const AddCentralSecretariat = () => {
  const { id } = useParams();
  const { data } = useFETCH(
    id !== "add" && `admin/v2/central-hq-info/items/${id}`
  );
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  let dataUp = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataUp?.name,
        job_title: dataUp?.job_title,
        phone_number: dataUp?.phone_number,
        email: dataUp?.email,
      });
  }, [dataUp]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id !== "add"
        ? `admin/v2/central-hq-info/items/${id}`
        : "admin/v2/central-hq-info/items"
    );
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6} className="space-y-4">
            <Imports
              title=" : الأسم "
              name="name"
              value={formData?.name}
              onChange={handleChange}
            />
            <Imports
              title=" : الموقع التنظيمي "
              name="job_title"
              value={formData?.job_title}
              onChange={handleChange}
            />
            <Imports
              title=" : رقم الهاتف "
              name="phone_number"
              value={formData?.phone_number}
              onChange={handleChange}
            />
            <Imports
              title=" : الايميل "
              name="email"
              value={formData?.email}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>
          {id !== "add" ? "تعديل" : "أضافة"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default AddCentralSecretariat;
