import { useParams } from "react-router-dom";
import { Border, ButtonApp, Container, Imports } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { useEffect } from "react";

const UpdateProvincialSecretariat4 = () => {
  const { id, idu } = useParams();
  const { data } = useFETCH(
    id !== "add" && `admin/v2/provincial-hqs/last-sub/subs/${idu}`
  );
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  useEffect(() => {
    setFormData({
      name: data?.data.data.name,
      job_title: data?.data.data.job_title,
      phone_number: data?.data.data.phone_number,
      email: data?.data.data.email,
    });
  }, [data?.data.data]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      idu === "add"
        ? `admin/v2/provincial-hqs/last-sub/${id}/subs`
        : `admin/v2/provincial-hqs/last-sub/subs/${idu}`
    );
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6}>
            <Imports
              value={formData?.name}
              onChange={handleChange}
              name="name"
              title=": الأسم"
            />
            <Imports
              value={formData?.job_title}
              onChange={handleChange}
              name="job_title"
              title=": الموقع التنظيمي "
            />
            <Imports
              value={formData?.phone_number}
              onChange={handleChange}
              name="phone_number"
              title=": رقم الهاتف"
            />
            <Imports
              value={formData?.email}
              onChange={handleChange}
              name="email"
              title=": الايميل"
            />
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>
          {idu !== "add" ? "تعديل" : "أضافة"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdateProvincialSecretariat4;
