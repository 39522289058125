import { useEffect, useRef, useState } from "react";
import { Border, ButtonApp, Container, Imports } from "../../components";
import { useParams } from "react-router-dom";
import { imgUrl } from "../../Context/baseUrl";
import useFetch from "../../Context/useFetch";
import userPhoto from "../../images/user.jpeg";
import { BiHide, BiShow } from "react-icons/bi";
import { usePOST } from "../../APIs/useMyAPI";
const CreateAdmin = () => {
  const { id } = useParams();
  const [checed, setChecked] = useState(false);
  const [checedArray, setCheckedArray] = useState([]);
  const [biShow, setBiShow] = useState(false);
  const [biShowP, setBiShowP] = useState(false);
  const {
    handleSubmit,
    setFormData,
    formData,
    handleChange,
    img,
    loading,
    setImg,
    error,
    deleteCheckArray2,
    deleteCheckArray,
    setDeleteCheck2,
    setName4,
  } = usePOST();
  const { data } = useFetch("admin/roles");
  const { data: provincesda } = useFetch(`admin/provinces`);
  const { data: data2 } = useFetch(`admin/admins/${id}`);
  let dataAll = data2?.data.data;

  useEffect(() => {
    id !== "create-account" &&
      setFormData({
        name: dataAll?.name,
        username: dataAll?.username,
        job: dataAll?.job,
        age: dataAll?.age,
        phone_number: dataAll?.phone_number,
        email: dataAll?.email,
        location: dataAll?.location,
        role_id: dataAll?.role_id,
      });
    id !== "create-account" &&
      setDeleteCheck2(dataAll?.views[28].provinces_ids);
    id !== "create-account" && setName4("provinces_ids[]");
  }, [dataAll]);

  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "create-account" ? "admin/admins" : `admin/admins/${id}`
    );
  };
  const { data: dataRole } = useFetch("admin/views");

  const selectRef = useRef(null);
  const checkRole = (e) => {
    setChecked(!checed);
    if (e.view === "presidential_candidate_volunteer_requests") {
      setBiShowP(true);
    }
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <div className="space-y-8">
          <div className="flex gap-4 max-md:flex-wrap flex-row-reverse space">
            <Imports
              value={formData?.name}
              name="name"
              title=":الاسم"
              onChange={handleChange}
            />
            <Imports
              value={formData?.username}
              name="username"
              title=":اسم المستخدم"
              onChange={handleChange}
            />
            <Imports
              value={formData?.job}
              name="job"
              title=":الوظيفة"
              onChange={handleChange}
            />
          </div>
          <div className="flex gap-4 max-md:flex-wrap flex-row-reverse">
            <Imports
              value={formData?.age}
              title=":العمر"
              name="age"
              onChange={handleChange}
            />
            <Imports
              value={formData?.email}
              title=":الايميل"
              name="email"
              onChange={handleChange}
            />
            <div className="relative w-full">
              <Imports
                value={formData?.password}
                type={biShow ? "text" : "password"}
                name="password"
                title=":كلمة السر"
                onChange={handleChange}
              />
              {biShow ? (
                <BiShow
                  onClick={() => setBiShow(false)}
                  size={20}
                  className="absolute left-0 top-5 cursor-pointer"
                />
              ) : (
                <BiHide
                  onClick={() => setBiShow(true)}
                  size={20}
                  className="absolute left-0 top-5 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="flex gap-4 max-md:flex-wrap flex-row-reverse">
            <Imports
              value={formData?.phone_number}
              title=":الهاتف"
              name="phone_number"
              onChange={handleChange}
            />
            <Imports
              value={formData?.location}
              title=":العنوان"
              name="location"
              onChange={handleChange}
            />
            <pre className="flex items-center border-b font-semibold text-Brown border-Brown -ml-4 justify-end  max-md:p-0">
              :الصلاحية
            </pre>
            <select
              className="outline-none border-b w-full border-Brown"
              id=""
              value={formData?.role_id}
              ref={selectRef}
              name="role_id"
              onChange={handleChange}
            >
              <option value=""></option>
              {data?.data.data.map((e) => (
                <option key={e.id} data-mode={e.has_views} value={e.id}>
                  {e.ar_name}
                </option>
              ))}
            </select>
          </div>
          <div
            className={`flex  flex-wrap gap-1 justify-center ${
              selectRef.current?.options[selectRef.current.selectedIndex]
                .dataset.mode === "1"
                ? ""
                : "hidden"
            }`}
          >
            {dataRole?.data?.data.map((e) => (
              <>
                <Border key={e.id}>
                  <div className="flex items-center  justify-end gap-2 w-[220px] text-end">
                    {e.name}
                    <input
                      type="checkbox"
                      value={e.id}
                      name="views_ids[]"
                      onClick={() => checkRole(e)}
                      className="w-[20px] h-[20px]"
                      onChange={deleteCheckArray}
                    />
                  </div>
                </Border>
              </>
            ))}
          </div>
          {biShowP && (
            <>
              <h1 className="font-semibold text-xl text-end my-3">
                اختيار محافظات
              </h1>
              <div className="flex  flex-wrap gap-1 justify-center ">
                {provincesda?.data?.data.map((e) => (
                  <>
                    <Border key={e.id}>
                      <div className="flex items-center  justify-end gap-2 w-[220px] text-end">
                        {e.name}
                        <input
                          type="checkbox"
                          value={e.id}
                          name="provinces_ids[]"
                          onClick={() => checkRole(e)}
                          className="w-[20px] h-[20px]"
                          onChange={deleteCheckArray2}
                        />
                      </div>
                    </Border>
                  </>
                ))}
              </div>
            </>
          )}
          <div className="flex justify-center flex-wrap gap-5">
            <div className="border-dashed border-[2px] border-Brown rounded-lg ">
              <div className="flex items-center justify-center ">
                <input
                  id="file-input"
                  type="file"
                  name="image"
                  onChange={handleChange}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <button
                  className="px-20 py-10"
                  onClick={() => document.getElementById("file-input").click()}
                >
                  أضف صورة الشخصية
                </button>
                <div className="flex flex-row-reverse">
                  {!img ? (
                    <div className="relative">
                      <img
                        src={`${
                          dataAll?.image ? imgUrl + dataAll?.image : userPhoto
                        }`}
                        alt=""
                        className="w-[300px] h-[220px] mx-auto"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {img && (
                    <div className="relative">
                      <div
                        onClick={() => {
                          setImg("");
                          setFormData("");
                        }}
                        className="bg-red-600 text-white w-5 h-5 rounded-full absolute -top-2 -left-1 z-10 flex cursor-pointer items-center justify-center "
                      >
                        X
                      </div>
                      <img
                        className="w-[300px] h-[220px] mx-auto"
                        src={img}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-red-500">{error}</div>
          <div>
            {isNaN(id) ? (
              <ButtonApp onClick={submitDisplay}>
                <span className="px-10">انشاء</span>
              </ButtonApp>
            ) : (
              <ButtonApp onClick={submitDisplay}>
                <span className="px-10">تعديل</span>
              </ButtonApp>
            )}
          </div>
        </div>
      </Border>
    </Container>
  );
};

export default CreateAdmin;
