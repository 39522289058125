import { useEffect } from "react";
import { Border, ButtonApp, Container, Imports } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const UpdateTitleSub = () => {
  const { id } = useParams();
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  const { data } = useFETCH(
    id === "party_list"
      ? "admin/v2/party-list/section-name"
      : id === "central-hq"
      ? "admin/v2/central-hq-info"
      : "admin/v2/party-info"
  );
  let xxxx =
    id === "party_list"
      ? data?.data.data.party_list_section_name
      : id === "central-hq"
      ? data?.data.data.data.section_name
      : data?.data.data.data.section_name;
  useEffect(() => {
    id === "party_list"
      ? setFormData({
          section_name: xxxx,
        })
      : id === "central-hq"
      ? setFormData({
          section_name: xxxx,
        })
      : setFormData({
          section_name: xxxx,
        });
  }, [xxxx]);

  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "party_list"
        ? "admin/v2/party-list/section-name"
        : id === "central-hq"
        ? "admin/v2/central-hq-info/section-name"
        : "admin/v2/party-info/section-name"
    );
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6}>
            {id === "party_list" ? (
              <Imports
                title=" : العنوان"
                name="section_name"
                value={formData?.section_name}
                onChange={handleChange}
              />
            ) : (
              <Imports
                title=" : العنوان"
                name="section_name"
                value={formData?.section_name}
                onChange={handleChange}
              />
            )}
          </Col>
        </Row>
        <div className="text-red-700"> {error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdateTitleSub;
