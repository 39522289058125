import { usePOST } from "../../../APIs/useMyAPI";
import { AddImage, Border, ButtonApp, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";

const UpdatePartyRequests = () => {
  const { handleSubmit, handleChange, loading, error } = usePOST({});
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(`admin/v2/join-party-requests/pdf`);
  };
  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6}>
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : pdf ملف
            </h1>
            <AddImage onChange={handleChange} name="pdf_file" title="pdf ملف" />
          </Col>
        </Row>
        <div className="text-red-700">{error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdatePartyRequests;
