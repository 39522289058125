import { Link } from "react-router-dom";
import Img2 from "../../images/edit (2).png";

const Title = ({ title, update, iconsShow, icon, link }) => {
  return (
    <div className=" p-2 px-7 border border-Brown  text-Brown rounded-2xl hover:bg-white gap-10 flex justify-between">
      <Link to={update || ""}>
        <img src={Img2} alt="" className="cursor-pointer" />
      </Link>
      {link ? (
        <a href={link || ""}>
          <h1 className="text-lg">{title}</h1>
        </a>
      ) : (
        <h1 className="text-lg">{title}</h1>
      )}

      <img
        src={icon}
        alt=""
        className={`mx-2 ${
          iconsShow === false ? "hidden" : ""
        } w-11 h-11 rounded-full`}
      />
    </div>
  );
};

export default Title;
