import { useEffect, useRef, useState } from "react";
import {
  Border,
  ButtonApp,
  Container,
  EditorHTML,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";

const Update = () => {
  const { id } = useParams();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  const { data } = useFETCH(
    `admin/presidential-candidates/profile/biography/items/${id}`
  );
  useEffect(() => {
    id !== "biography-items" &&
      setFormData({
        ...formData,
        title: formData?.title || data?.data.data.title,
        text: content || data?.data.data.text,
      });
    id === "biography-items" &&
      setFormData({
        ...formData,
        text: content,
      });
  }, [content, data?.data.data]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "biography-items"
        ? "admin/presidential-candidates/profile/biography/items"
        : `admin/presidential-candidates/profile/biography/items/${id}`
    );
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={7}>
            <Imports
              name="title"
              value={formData?.title}
              onChange={handleChange}
              title=" : العنوان"
            />
            <br />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <div>
              <JoditEditor
                ref={editor}
                value={data?.data.data.text}
                onChange={(newContent) => setContent(newContent)}
              />
            </div>
          </Col>
        </Row>
        <div className="text-red-700"> {error}</div>
        <ButtonApp onClick={submitDisplay}>
          {id === "biography-items" ? "أضافة" : "تعديل"}
        </ButtonApp>
      </Border>
    </Container>
  );
};

export default Update;
