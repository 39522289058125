import {
  Assistance,
  AssistanceTwo,
  CreateAccount,
  Educational,
  EducationalTwo,
  EnterHotels,
  EnterHotelsTwo,
  Juerrys,
  JuerrysTwo,
  Meeting,
  MeetingTwo,
  Memberships,
  MembershipsTwo,
  Powers,
  PowersTwo,
  Programs,
  ProgramsTwo,
  PublicInformation,
  Seminars,
  SeminarsTwo,
  Users,
  CreateAdmin,
  UsersTwo,
  ViewAdminAccounts,
  JuerrysThree,
  JuerrysFour,
  MaydayRequests,
  AttendanceList,
  Services,
  ProgramsDate,
  ProgramsAddDate,
  Provinces,
  ProvincesAdd,
  Hotel,
  UpdateHotel,
  CangePassword,
  UpditePublicInformation,
  OrganizationalWork,
  PresidentialCandidate,
  CV,
  Videos,
  VolunteerRequests,
  PresidentialProgramme,
  AboutTheParty,
  ConferencesAndEvents,
  PartyNews,
  PartyList,
  PartyRequests,
  ProvincialSecretariat,
  ProvincialSecretariat2,
  ProvincialSecretariat3,
  ProvincialSecretariat4,
  ParliamentaryPremises,
  CentralSecretariat,
  UpdateTitle,
  UpdatePresidentialCandidate,
  UpdateCV,
  UpdateVideos,
  UpdatePresidentialProgramme,
  UpdateAboutTheParty,
  UpdateAboutTheParty2,
  AddConferencesAndEvents,
  AddPartyNews,
  AddProvincial,
  UpdateProvincialSecretariat3,
  UpdateProvincialSecretariat4,
  AddParliamentaryPremises,
  UpdateCentralSecretariat,
  AddCentralSecretariat,
  UpdatePresidentialProgramme2,
  UpdateTitleSub,
  UpdatePartyRequests,
  Honesty,
  HonestyAdd,
  UpdatePartyList,
  ProvincialSecretariatU2,
  ProvincialSecretariatu3,
  Notifications,
  AddNotifications,
} from "./pages/index";
import { Navbar, SideBar } from "./components/index";
import { Route, Routes } from "react-router-dom";
import Logo from "./images/logo.png";
import Login from "./pages/Login/Login";
import RequireAuth, { RequireLogin, RequireUsers } from "./Context/RequireAuth";
import Update from "./pages/V===2/PresidentialCandidate/Update";
import AudienceRequests from "./pages/V===2/AudienceRequests/AudienceRequests";

const App = () => {
  return (
    <>
      <div className=" relative flex ">
        <div className=" container mx-auto relative">
          <Navbar />
          <Routes>
            <Route
              path={"/login"}
              element={
                <RequireLogin>
                  <Login />
                </RequireLogin>
              }
            />

            <Route element={<RequireAuth />}>
              {/* ---------------------------------------------------------------------- */}
              <Route
                index
                element={
                  <h1 className="grid place-content-center h-[80vh]">
                    <img src={Logo} alt="" className="w-[250px]" />
                  </h1>
                }
              />
              {/* ---------------------------------------------------------------------- */}
              <Route
                path="500"
                element={
                  <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold text-5xl">
                    500 | Internal Server Error
                  </h1>
                }
              />
              {/* ---------------------------------------------------------------------- */}
              <Route
                path="*"
                element={
                  <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold text-5xl">
                    404 | no page found
                  </h1>
                }
              />
              {/* ---------------------------------------------------------------------- */}
              <Route
                path="/403"
                element={
                  <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold text-5xl">
                    403 | FORBIDDEN
                  </h1>
                }
              />
              {/* ------------------------------------------------------------ */}
              <Route element={<RequireUsers name="users" />}>
                <Route path="users">
                  <Route index element={<Users />} />
                  <Route path=":id" element={<UsersTwo />} />
                  <Route path="account/:id" element={<CreateAccount />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="site_info" />}>
                <Route path="publicInformation">
                  <Route index element={<PublicInformation />} />
                  <Route
                    path="Updite-Public-Information"
                    element={<UpditePublicInformation />}
                  />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route path="changePassword">
                <Route index element={<CangePassword />} />
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="admins" />}>
                <Route path="admin-account">
                  <Route index element={<ViewAdminAccounts />} />
                  <Route path=":id" element={<CreateAdmin />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="provinces" />}>
                <Route path="provinces">
                  <Route index element={<Provinces />} />
                  <Route path=":id" element={<ProvincesAdd />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="memberships" />}>
                <Route path="Memberships">
                  <Route index element={<Memberships />} />
                  <Route path=":id" element={<MembershipsTwo />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="roles" />}>
                <Route path="Powers">
                  <Route index element={<Powers />} />
                  <Route path="updete/:id" element={<PowersTwo />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="educational_subjects" />}>
                <Route path="educational">
                  <Route index element={<Educational />} />
                  <Route path=":id" element={<EducationalTwo />} />
                </Route>
              </Route>
              {/* --v2------v2-----v2-----v2------v2---------v2---------v2----------v2-------v2------v2----- */}
              {/* <Route element={<RequireUsers name="........." />}>  </Route> */}
              <Route path="update-title/:id" element={<UpdateTitle />} />
              <Route path="update-title-sub/:id" element={<UpdateTitleSub />} />
              <Route path="Update/:id" element={<Update />} />
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="honesties" />}>
                <Route path="honesty">
                  <Route index element={<Honesty />} />
                  <Route path=":id" element={<HonestyAdd />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="parliamentary_hq" />}>
                <Route path="parliamentary-premises">
                  <Route index element={<ParliamentaryPremises />} />
                  <Route path=":id" element={<AddParliamentaryPremises />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="party_list" />}>
                <Route path="party-list">
                  <Route index element={<PartyList />} />
                  <Route path=":id" element={<UpdatePartyList />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="party_news" />}>
                <Route path="party-news">
                  <Route index element={<PartyNews />} />
                  <Route path=":id" element={<AddPartyNews />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="join_party_requests" />}>
                <Route path="party-requests">
                  <Route index element={<PartyRequests />} />
                  <Route path="update" element={<UpdatePartyRequests />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="notifications" />}>
                <Route path="notifications">
                  <Route index element={<Notifications />} />
                  <Route path=":id" element={<AddNotifications />} />
                </Route>
              </Route>
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="party_info" />}>
                <Route path="about-the-party" element={<AboutTheParty />} />
                <Route
                  path="update-about-the-party/:id"
                  element={<UpdateAboutTheParty />}
                />
                <Route
                  path="update-about-the-party2"
                  element={<UpdateAboutTheParty2 />}
                />
              </Route>

              {/* audience requests  */}
              <Route
                path="audience_requests"
                element={<AudienceRequests />}
              />
              {/* ---------------------------------------------------------------------- */}
              <Route element={<RequireUsers name="presidential_candidate" />}>
                <Route
                  path="presidential-candidate/videos"
                  element={<Videos />}
                />
                <Route path="presidential-candidate/cv" element={<CV />} />
                <Route
                  element={
                    <RequireUsers name="presidential_candidate_volunteer_requests" />
                  }
                  f
                >
                  <Route
                    path="presidential-candidate/volunteer-requests"
                    element={<VolunteerRequests />}
                  />
                </Route>
                <Route
                  path="presidential-candidate/presidential-programme"
                  element={<PresidentialProgramme />}
                />
                <Route
                  path="presidential-candidate"
                  element={<PresidentialCandidate />}
                />
                <Route
                  path="update-presidential-candidate"
                  element={<UpdatePresidentialCandidate />}
                />
                <Route path="update-CV" element={<UpdateCV />} />
                <Route path="update-videos/:id" element={<UpdateVideos />} />
                <Route
                  path="update-presidential-programme"
                  element={<UpdatePresidentialProgramme />}
                />
                <Route
                  path="/update-presidential-programme2/:id"
                  element={<UpdatePresidentialProgramme2 />}
                />
              </Route>
              <Route element={<RequireUsers name="events_bulletin" />}>
                <Route
                  path="conferences-and-events"
                  element={<ConferencesAndEvents />}
                />
                <Route
                  path="conferences-and-events/:id"
                  element={<AddConferencesAndEvents />}
                />
              </Route>
              <Route element={<RequireUsers name="central_hq" />}>
                <Route
                  path="central-secretariat"
                  element={<CentralSecretariat />}
                />
                <Route
                  path="central-secretariat/:id"
                  element={<UpdateCentralSecretariat />}
                />
                <Route
                  path="central-secretariat/add/:id"
                  element={<AddCentralSecretariat />}
                />
              </Route>
              <Route element={<RequireUsers name="provincial_hq" />}>
                <Route
                  path="provincial-secretariat"
                  element={<ProvincialSecretariat />}
                />
                <Route
                  path="provincial-secretariat/u/:id"
                  element={<AddProvincial />}
                />
                <Route
                  path="provincial-secretariat/:id/page2"
                  element={<ProvincialSecretariat2 />}
                />
                <Route
                  path="provincial-secretariat/:id/page2/:idu"
                  element={<ProvincialSecretariatU2 />}
                />
                <Route
                  path="provincial-secretariat/:id/:idu/page3"
                  element={<ProvincialSecretariat3 />}
                />
                <Route
                  path="provincial-secretariat/:id/page3/:idu"
                  element={<ProvincialSecretariatu3 />}
                />
                <Route
                  path="provincial-secretariat/:id/page3/u/:idu"
                  element={<UpdateProvincialSecretariat3 />}
                />
                <Route
                  path="provincial-secretariat/:id/page4"
                  element={<ProvincialSecretariat4 />}
                />
                <Route
                  path="provincial-secretariat/:id/page4/:idu"
                  element={<UpdateProvincialSecretariat4 />}
                />
              </Route>
              {/* --v2------v2-----v2-----v2------v2---------v2---------v2----------v2-------v2------v2----- */}
              <Route path="organizational-work">
                <Route index element={<OrganizationalWork />} />
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="seminars" />}>
                  <Route path="seminars">
                    <Route index element={<Seminars />} />
                    <Route path=":id" element={<SeminarsTwo />} />
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="forum_videos" />}>
                  <Route path="meeting">
                    <Route index element={<Meeting />} />
                    <Route path=":id" element={<MeetingTwo />} />
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="audiences" />}>
                  <Route path="assistance">
                    <Route index element={<Assistance />} />
                    <Route path=":id" element={<AssistanceTwo />} />
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="medical_reliefs_orders" />}>
                  <Route path="MaydayRequests">
                    <Route index element={<MaydayRequests />} />
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="attendants" />}>
                  <Route path="AttendanceList">
                    <Route index element={<AttendanceList />} />
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="paramedics" />}>
                  <Route path="firstaid">
                    <Route index element={<Services />} />
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="hotels" />}>
                  <Route path="hotels">
                    <Route index element={<EnterHotels />} />
                    <Route
                      path="create-Enter-hotels"
                      element={<EnterHotelsTwo />}
                    />
                    <Route path=":id" element={<Hotel />} />
                    <Route path="UpdateHotel/:id" element={<UpdateHotel />} />
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="competent_committees" />}>
                  <Route path="juerrys">
                    <Route index element={<JuerrysThree />} />
                    <Route path=":id" element={<Juerrys />} />
                    <Route path=":id/:idU" element={<JuerrysTwo />} />
                    <Route path="item/:id" element={<JuerrysFour />} />
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
                <Route element={<RequireUsers name="forum_program_days" />}>
                  <Route path="programs-Date">
                    <Route index element={<ProgramsDate />} />
                    <Route path="date/:id" element={<ProgramsAddDate />} />
                    <Route
                      element={
                        <RequireUsers name="forum_program_days_lectures" />
                      }
                    >
                      <Route path=":id" element={<Programs />} />
                      <Route path=":id/:idU" element={<ProgramsTwo />} />
                    </Route>
                  </Route>
                </Route>
                {/* ---------------------------------------------------------------------- */}
              </Route>
            </Route>
          </Routes>
        </div>
        <SideBar />
      </div>
    </>
  );
};
export default App;
