import { BodyCard, Border, CardAll, Container } from "../../../components";
import { Col, Row } from "../../../Grid-system";
import Title from "../../../components/Title/Title";
import { Link } from "react-router-dom";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import { imgUrl } from "../../../Context/baseUrl";
import { useEffect, useState } from "react";
const PresidentialCandidate = () => {
  const [check, setCheck] = useState(false);
  const { data, isLoading } = useFETCH(`admin/presidential-candidates/profile`);
  const { data: dataCheck } = useFETCH(
    `admin/presidential-candidates/display-status?info=presidential_candidate`
  );
  const { handleSubmit, setFormData, formData } = usePOST(
    dataCheck?.data.data.is_presidential_candidate_shown_in_app === "1"
      ? true
      : false
  );
  useEffect(() => {
    setFormData({
      info: "presidential_candidate",
      status:
        dataCheck?.data.data.is_presidential_candidate_shown_in_app === "1"
          ? "0"
          : "1",
    });
  }, [dataCheck?.data.data.is_presidential_candidate_shown_in_app]);
  const submitDisplay = (e) => {
    setCheck(!check);
    handleSubmit(`admin/presidential-candidates/display-status`, true);
  };

  return (
    <>
      {isLoading ? (
        <div className="loading"></div>
      ) : (
        <Container>
          <Row justify="center">
            <Col md={5}>
              <Title
                update="/update-title/presidential_candidate"
                title={data?.data.data.views_info.presidential_candidate_title}
                icon={
                  imgUrl +
                  data?.data.data.views_info.presidential_candidate_icon
                }
              />
            </Col>
          </Row>
          <Border>
            <Row justify="end">
              <Col md={5}>
                <CardAll
                  update="/update-presidential-candidate"
                  deleteShow={false}
                >
                  <div className="w-[100px] h-[100px] m-4 overflow-hidden rounded-full mx-auto">
                    <img
                      src={imgUrl + data?.data.data.profile.image}
                      alt=""
                      className="w-full h-full bg-black"
                    />
                  </div>
                  <BodyCard
                    name=" : الاسم "
                    value={data?.data.data.profile.name}
                  />
                  <BodyCard
                    name=" : المنصب "
                    value={data?.data.data.profile.position}
                  />
                </CardAll>
              </Col>
              <Col md={3}>
                <div className="flex  items-center justify-end">
                  <pre>اظهار في التطبيق</pre>
                  <input
                    type="checkbox"
                    onClick={submitDisplay}
                    checked={
                      dataCheck?.data.data
                        .is_presidential_candidate_shown_in_app === "1" || check
                        ? true
                        : false
                    }
                    className="w-5 h-5 cursor-pointer"
                  />
                </div>
              </Col>
            </Row>
            <Row justify="center" className="pt-4">
              <Col md={5}>
                <Link to="/presidential-candidate/cv">
                  <Title
                    update="/update-title/presidential_candidate_profile"
                    title={
                      data?.data.data.views_info
                        .presidential_candidate_profile_title
                    }
                    icon={
                      imgUrl +
                      data?.data.data.views_info
                        .presidential_candidate_profile_icon
                    }
                  />
                </Link>
              </Col>
              <Col md={5}>
                <Link to="/presidential-candidate/videos">
                  <Title
                    update="/update-title/presidential_candidate_videos"
                    title={
                      data?.data.data.views_info
                        .presidential_candidate_videos_title
                    }
                    icon={
                      imgUrl +
                      data?.data.data.views_info
                        .presidential_candidate_videos_icon
                    }
                  />
                </Link>
              </Col>
              <Col md={5}>
                <Link to="/presidential-candidate/volunteer-requests">
                  <Title
                    update="/update-title/presidential_candidate_volunteer_requests"
                    title={
                      data?.data.data.views_info
                        .presidential_candidate_volunteer_requests_title
                    }
                    icon={
                      imgUrl +
                      data?.data.data.views_info
                        .presidential_candidate_volunteer_requests_icon
                    }
                  />
                </Link>
              </Col>
              <Col md={5}>
                <Link to="/presidential-candidate/presidential-programme">
                  <Title
                    update="/update-title/presidential_candidate_programme"
                    title={
                      data?.data.data.views_info
                        .presidential_candidate_programme_title
                    }
                    icon={
                      imgUrl +
                      data?.data.data.views_info
                        .presidential_candidate_programme_icon
                    }
                  />
                </Link>
              </Col>
            </Row>
          </Border>
        </Container>
      )}
    </>
  );
};

export default PresidentialCandidate;
