import { Link } from "react-router-dom";
import useFetch from "../../Context/useFetch";
import {
  Add,
  CardAll,
  Container,
  DeleteIcon,
  Error,
} from "../../components/index";
import { BiEdit, BiTrashAlt } from "react-icons/bi";

const Memberships = () => {
  const { data, loading, error, deleteItem, deleteError } = useFetch(
    "admin/memberships?",
    "admin/memberships"
  );
  let dataAll = data?.data.data;
  const deleteItemThis = (e) => {
    dataAll?.filter((p) => p.id !== e.id); //p.id !== e.id
    deleteItem(e);
    return dataAll;
  };
  let x = JSON.parse(localStorage.getItem("memberships")) || "";
  return (
    <>
      <Error error={error} onClick={deleteError} />
      <Container>
        <div className=" mx-auto w-full  py-4 overflow-x-scroll">
          <table className=" text-2xl text-center w-full mx-auto ">
            <thead className="text-Brown">
              <tr>
                <td>العضويات</td>
                <td
                  className={`${
                    x[1] === "cpanel_read_only_admin" ? "hidden" : ""
                  }`}
                >
                  احداث
                </td>
              </tr>
            </thead>
            <tbody className="">
              {loading ? (
                <tr>
                  <td className="border-none" colSpan={5}>
                    <div>
                      <div className="loading"></div>
                    </div>
                  </td>
                </tr>
              ) : (
                dataAll?.map((e) => (
                  <tr kay={e.id}>
                    <td>{e.name}</td>
                    <td
                      className={`${
                        x[1] === "cpanel_read_only_admin" ? "hidden" : ""
                      }`}
                    >
                      <div className="flex justify-center gap-6 ">
                        <Link to={`/Memberships/${e.id}`}>
                          <button className="cursor">
                            <BiEdit size={35} color={"Brown"} />
                          </button>
                        </Link>
                        <DeleteIcon onClick={() => deleteItemThis(e)} />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <Add check="memberships" link="/Memberships/create-Memberships" />
      </Container>
    </>
  );
};

export default Memberships;
