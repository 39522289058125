import { useEffect, useRef, useState } from "react";
import {
  AddImage,
  Border,
  ButtonApp,
  Container,
  EditorHTML,
  Imports,
} from "../../../components";
import { Col, Row } from "../../../Grid-system";
import { useFETCH, usePOST } from "../../../APIs/useMyAPI";
import JoditEditor from "jodit-react";

const UpdatePresidentialProgramme = () => {
  const { data } = useFETCH(`presidential-candidates/programme/info`);
  const { handleSubmit, setFormData, formData, handleChange, loading, error } =
    usePOST();
  useEffect(() => {
    setFormData({
      presidential_programme_text: data?.data.data.presidential_programme_text,
    });
  }, [data?.data.data]);
  const submitDisplay = (e) => {
    e.preventDefault();
    handleSubmit("admin/presidential-candidates/programme/info");
  };

  return (
    <Container>
      {loading ? (
        <div>
          <div className="loading"></div>
        </div>
      ) : (
        ""
      )}
      <Border>
        <Row justify="center">
          <Col md={6} className="space-y-4">
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : الملف
            </h1>
            <AddImage
              onChange={handleChange}
              name="presidential_programme_pdf"
              title="ملف pdf "
            />
            <h1 className="flex items-center font-semibold text-Brown justify-end m-2">
              : النص
            </h1>
            <div>
              <Imports
                value={formData?.presidential_programme_text}
                name="presidential_programme_text"
                onChange={handleChange}
              />
            </div>
          </Col>
        </Row>
        <div className="text-red-700"> {error}</div>
        <ButtonApp onClick={submitDisplay}>تعديل</ButtonApp>
      </Border>
    </Container>
  );
};

export default UpdatePresidentialProgramme;
