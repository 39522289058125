import { Link } from "react-router-dom";

const Add = (props) => {
  let x = JSON.parse(localStorage.getItem(props.check || "users")) || "";
  return (
    <div
      className={`fixed w-10 bottom-16 left-10 ${
        x[1] === "cpanel_cru_admin"
          ? ""
          : x[1] === "cpanel_read_only_admin"
          ? "hidden"
          : ""
      }`}
    >
      <Link to={props.link || ""}>
        <div
          className="rounded-full relative font-semibold text-white w-[50px] text-5xl h-[50px] p-0 bg-Brown"
          onClick={props.click}
        >
          <span className="absolute  top-[43%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
            +
          </span>
        </div>
      </Link>
    </div>
  );
};

export default Add;
